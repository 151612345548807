
import { endOfMonth, subMonths } from 'date-fns';
import ProjectProps from './ProjectProps'
import ProjectDriver from './driver/ProjectDriver';




const projectProps = extendValues => class extends ProjectProps {};
class Project extends projectProps({}) {

  is_unit_price = true
  is_over_time_fee = true
  is_over_distance_fee = true


  validateForm(form){
    return []
  }
  setTimesetMinAndHour(){
    if(!this.time_set) return this 
    const h =  Math.floor(this.time_set / 60 )
    const m =  this.time_set - ( h * 60)
    console.log(h)
    console.log(m)
    const new_project = this.set("time_set_h", h).set("time_set_m", m)
    return new_project
  }

  getFormData(){
    const new_item = this.set("time_set", 60 * Number(this.time_set_h ) + Number(this.time_set_m ))
    return new_item
  }

  getOverTimeString(){
    if(!this.time_set) return ""
    const h =  Math.floor(this.time_set / 60 )
    const m =  this.time_set - ( h * 60)
    return h + "時間" + m + "分"
  }

  

  isWorkingDriver(driver_id){
    const _list = this.getWorkingDriver()
    if(_list.find(item => item.driver_id === driver_id)){
      return true
    }
    return false
  } 

  hasWorkingDriver(){
   return  this.getWorkingDriver().length > 0
  }

  getWorkingDriver(){
    if(!this.drivers) return []
    const _drivers = this.drivers.map(_project_driver => {
      const project_driver = new ProjectDriver(_project_driver)
      if(project_driver.is_working()){
        return project_driver
      }else{
        return null
      }
    })
    return _drivers.filter(item => item !== null)
  }

  getNotWorkingDriver(){
    if(!this.drivers) return []
    const _drivers = this.drivers.map(_project_driver => {
      const project_driver = new ProjectDriver(_project_driver)
      if(!project_driver.is_working()){
        return project_driver
      }else{
        return null
      }
    })
    return _drivers.filter(item => item !== null)
  }


  getLatestReportMonthDate(){
    const today = new Date()
    return today
  }

  getStartDateInTargetMonth(year, month){
    if(Number(this.closing_date_day) > 28 ){
      return new Date(year, month - 1 , 1)
    }
    return subMonths(new Date(year, month - 1 , Number(this.closing_date_day) + 1),1)
   
  }

  getEndDateInTargetMonth(year, month){
    if(Number(this.closing_date_day) > 28 ){
      return endOfMonth(new Date(year, month -1  , 1))
    }
    return new Date(year, month - 1, Number(this.closing_date_day) )
   
  }

  getEndDateString(){
    if(Number(this.closing_date_day) === 31 ){
      return "末日締め"
    }
    return this.closing_date_day + "日締め"
  }

 

  getReportMonthAndYear(target_date){
    var date = target_date.getDate();

    var new_target_date = target_date

    switch(this.closing_date){
     
      case 2 :  {
        if(date <= 25){
          new_target_date = subMonths(target_date,1)
        }     
        break;
      }
     
      case 3 :  {
        if(date <= 20){
          new_target_date = subMonths(target_date,1)
        }     
        break;
      }

      case 4 :  {
        if(date <= 15){
          new_target_date = subMonths(target_date,1)
        }     
        break;
      }
     
      default : {}
    }

    return [new_target_date.getFullYear(), new_target_date.getMonth() + 1]
  }
  

  getOverTimeUnitTime(){
    return this.overtime_unit
    /**switch(this.overtime_fee_type){
      case 1 : return 1
      case 2 : return 5
      case 3 : return 10
      case 4 : return 15
      case 5 : return 15
      case 6 : return 30
      case 7 : return 30
      case 8 : return 30
      case 9 : return 30
      case 10 : return 30
      case 11 : return 60
      default : return null
    }*/
  }

}
export default Project

