
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../../action/Action';

const ProjectDriverAction = {

  getListByIds(token,  ids, callback, errorHandler){
    const data = new FormData() 
    data.append('ids', ids.join(","))
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_PROJECT") +  "0/drivers/?" + queryString

    Action.fetchAuthorizedJson( url,  token,  "GET", {}, null,  callback, errorHandler)
  },

  getListByPartners(token,  ids, callback, errorHandler){
    const data = new FormData() 
    data.append('user_ids', ids.join(","))
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_PROJECT") +  "0/drivers/?" + queryString

    Action.fetchAuthorizedJson( url,  token,  "GET", {}, null,  callback, errorHandler)
  },

  getListByPartner(token,  partner_id, callback, errorHandler){
    const data = new FormData() 
    data.append('partner_id', partner_id)
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_PROJECT") +  "0/drivers/?" + queryString

    Action.fetchAuthorizedJson( url,  token,  "GET", {}, null,  callback, errorHandler)
  },


  getListByPartnerAndPeriod(token,  partner_id,  start_Date, end_date, callback, errorHandler){
    const data = new FormData() 
    data.append('partner_id', partner_id)
    data.append('start_date', start_Date)
    data.append('end_date', end_date)

   
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_PROJECT") +  "0/drivers/?" + queryString
    Action.fetchAuthorizedJson( url,  token,  "GET", {}, null,  callback, errorHandler)
  },

  getListByClientAndPeriod(token,  client_id,  start_Date, end_date, callback, errorHandler){
    const data = new FormData() 
    data.append('client_id', client_id)
    data.append('start_Date', start_Date)
    data.append('end_date', end_date)
    
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_PROJECT") +  "0/drivers/?" + queryString

    Action.fetchAuthorizedJson( url,  token,  "GET", {}, null,  callback, errorHandler)
  },


  
  getListByProject(token, project_id, callback, errorHandler){
    const data = new FormData() 
    data.append('project_id', project_id)
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_PROJECT") +  "0/drivers/?" + queryString

    Action.fetchAuthorizedJson( url,  token,  "GET", {}, null,  callback, errorHandler)
  },



  getMyList(token, target_date, client_id, project_id, partner_id, driver_id, with_spot, with_zero, for_payment, with_project, limit, page, callback, errorHandler){
    const data = new FormData() 
    if(target_date)
      data.append('target_date', target_date)
    if(client_id)
      data.append('client_id', client_id)
    if(project_id)
      data.append('project_id', project_id)
    if(partner_id)
      data.append('partner_id', partner_id)
    if(driver_id)
      data.append('driver_id', driver_id)

      
    data.append("limit", limit)
    if(limit && page)
     data.append("offset", limit * (page - 1))
    data.append("with_spot", with_spot)
    data.append("with_zero", with_zero)
    data.append("for_payment", for_payment)
    data.append('with_project', with_project)
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_PROJECT") +  "mine/?" + queryString
    Action.fetchAuthorizedJson( url, token, "GET", {}, null,  callback, errorHandler)
  },


  getDetail(token, project_id, id,  callback, errorHandler){
    Action.fetchJson( config.get("API_PATH") + config.get("API_PROJECT") + project_id + "/drivers/" + id ,  "GET", {}, null,  callback, errorHandler)
  },


  create( token, project_id, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_PROJECT") + project_id + "/drivers/" + item.id  : config.get("API_PATH") + config.get("API_PROJECT") + project_id + "/drivers/"
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },

  delete( token, project_id, id,  callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_PROJECT")  + project_id + "/drivers/" + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },


  hasCookie(item, callback, errorHandler){
    const project_driver_form =  cookie.load("project_driver_form")
    if(project_driver_form) return true 
    return false
  },

  saveOnCookie(item){
    cookie.save('project_driver_form',item, { path: '/' })
  },

  loadFromCookie( callback, errorHandler){
    const driver =  cookie.load("project_driver_form")
    if(driver) {
      callback({data:driver})
    }else{
      errorHandler({"error":"not found"})
    }
  },
  removeCookie(){
    cookie.remove('project_driver_form', { path: '/' })
  }
 
 
}

export default ProjectDriverAction
