import React, { useEffect, useState } from 'react'

import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps'
import DriverDetailItem from './DriverDetailItem'
import BankInfo from '../bankinfo/BankInfo';



function DriverDetail(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)

  
  useEffect(() => {
    if(props.driver){
      var p_driver = props.driver
    
      if(props.profile && props.driver.user_id === null){
        p_driver = p_driver.set("user_id", props.profile.id)
      }
      p_driver = p_driver.set("bank_info", new BankInfo(props.driver.bank_info))
      setItem(p_driver)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.driver, props.profile])



  const changeHandler = (e) => {
    setItem(e)
  } 
  

  const btnClickHandler = () => {
    
    const errors = getErrorMessagte()
    setErrorMessage(null)
    if(errors.length >0 ){
      setErrorMessage(errors.map(item => <div>{item}</div>))
    }else{
      props.updateHandler(item)
    }
  }

  const getErrorMessagte = () => {
    var errors = []
    if(!item.name || item.name === "") errors.push("ドライバー名が未入力です。")
    if(!item.kana || item.kana === "") errors.push("フリガナが未入力です。")
    if(item.kana &&  !item.kana.match(/^[ァ-ヶー　]+$/)) errors.push("フリガナは全角カタカナで入力してください。")

    if(!item.user_id || item.user_id === "") errors.push("担当マネージャーが未入力です。")
    if(!item.partner_id || item.partner_id === "") errors.push("所属企業が未入力です。")
    
    if(!item.tel || item.tel === "") errors.push("電話番号が未入力です。")
    if(item.tel && !item.tel.match(/^[0-9]+$/))  errors.push("電話番号は半角数字で入力してください。")
    if((item.partner && item.partner.is_self === 1 ) || item.company_driver === 1){
      if(!item.commision )  errors.push("支払い手数料が未入力です。")
  
      if(item.payment_day_month === null || item.payment_day_month === "" )  errors.push("支払いサイトが未入力です。")
      if(!item.bank_info || !item.bank_info.bank_name || !item.bank_info.branch_name || !item.bank_info.account_type || !item.bank_info.account_name || !item.bank_info.account_id )  errors.push("振込先情報が未入力です。")
    }
    return errors
  }


  const btnSaveOnCookieHandler = () => {
    props.saveOnCookieHandler(item)
  }

  return(
    <div className="inner">
       <form >  
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">{item ? item.id ? "ドライバー情報編集" :"新規ドライバー登録" : null}</h2>
                <Breadcrumbs pages={[new LinkProps("ドライバー一覧","/driver"),new LinkProps(item ?item.id ? "ドライバー情報編集" :"新規ユドライバー登録": null,"#")]} />
						</div>
						<div className="container">
            {item && !item.id && props.has_cookie === true ?<div className="link right" onClick={props.loadFromCookie}><i className="fa-solid fa-rotate-right"></i> 下書きから読み込む</div> : null}
                <div className="column"><p className="container_name">{item && item.id ? "ドライバー情報":"新規ドライバー登録"}</p>
                {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                  <div className="input_wrap">
                    <DriverDetailItem  token={props.token} driver={item} is_edit={true} changeHandler={changeHandler}/>
                  </div>
                  {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                  <div className="button_wrap center">
                    {item && !item.id ? <button className="textbutton" type="button"  onClick={btnSaveOnCookieHandler}>下書き保存</button> : null}
                    <button className="red" type="button" onClick={btnClickHandler}>登録内容確認</button>
                  </div>
                </div>
            </div>
            
            
				</div>
                               
       </form>
		</div>
    
  )
}

export default DriverDetail

/**
 *  <select name="partner_id" value={item ? item.partner_id : undefined}  onChange={changeHandler} >
                              <option value="" selected="">選択してください</option>
                              {partners}
                              </select>
 */