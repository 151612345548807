import React, {useState,useEffect} from 'react';
import config from 'react-global-configuration';
import {  setMonth, setDate, setYear } from 'date-fns';
import Report from '../../../components/report/Report';
import ReportSpotCharter from '../../../components/report/report_category/ReportSpotCharter';
import ReportYamato from '../../../components/report/report_category/ReportYamato';
import ReportAmazon from '../../../components/report/report_category/ReportAmazon';
import ReportYusei from '../../../components/report/report_category/ReportYusei';
import ReportSpotCorporate from '../../../components/report/report_category/ReportSpotCorporate';
import ReportSpotPersonal from '../../../components/report/report_category/ReportSpotPersonal';
import ReportCorporate from '../../../components/report/report_category/ReportCorporate';
import ReportCharter from '../../../components/report/report_category/ReportCharter';
import { DeleteWindow } from '../delete_window/DeleteWindow';
import { ReportDetail } from '../report_detail/ReportDetail';
import { ImageBox } from './ImageBox';
import { PreviewBox }  from "./PreviewBox";
import Driver from '../../../components/drivers/Driver';

import ProjectDetailItem from '../../../components/project/ProjectDetailItem';
import useProjectDriverState from '../../../components/project/driver/useProjectDriverState';
import usePartnerState from '../../../components/partners/usePartnerState';
import ProjectDriverDetailItem from '../../../components/project/driver/ProjectDriverDetailItem';
import PartnerDetailItem from '../../../components/partners/PartnerDetailItem';
import ReportDriverFee from '../../../components/report/ReportDriverFee';
import { ReceiptBox } from '../receipt/ReceiptBox';
import Receipt from '../../../receipt/Receipt';
import useReceiptState from '../../../receipt/useReceiptState';
import useReportState from '../../../components/report/useReportState';
import useReportFormatState from '../../../components/monthly_report/format/usReportFormatState';

import ReportCommition from '../../../components/report/report_category/ReportCommition';
import ReportCommitionAndDate from '../../../components/report/report_category/ReportCommitionAndDate';
interface DetailWindowProps {
  token:String,
  report:Report,
  closeHandler:Function,
  updateHandler:Function,
  url:any,
  profile:any
}

/**
 * Primary UI component for user interaction
 */
export const DetailWindow = ({
  token,
  report,
  closeHandler,
  updateHandler,
  url,
  profile,
  ...props
}: DetailWindowProps) => { 

  
  const [item ,setItem] = useState(null)
  const [report_class ,setReportClass] = useState(null)
  const [is_delete, setIsDelete] = useState(null)
  const [delete_window, setDelelteWindow] = useState(null)
  const [receipts, setReceipts] = useState(null)
  const [up_uploaded_receipts, setUnUploadedReceipts] = useState([])
  const [textMessage, setTextMessage] = useState(null)

  const [up_uploaded_receipts_updated_at, setUnUploadedReceiptsUpdatedAt] = useState(null)
  //const [project_driver ,setProjectDriver] = useState(null)
  const { project_driver, getProjectDriver} = useProjectDriverState()
  const {partner, getPartner, getPartnerByUserId} = usePartnerState()
  const {receipt_created, createReceipt} = useReceiptState()
  const {updated_report, getReport} = useReportState()
  const{report_format_list, getReportFormatList} = useReportFormatState()
  useEffect(() => {
    getReportFormatList(token)
    if(report.project.project_type === 1 && !project_driver){
      getProjectDriver(token, report.project.uid, report.project_driver_id)
    }
    if(report.driver && report.driver.partner){ //自社ドライバーの場合
      getPartner(token, report.driver.partner.id)
    }else if(report.project.project_type === 2 && !partner){
      getPartnerByUserId(token,report.project.user_id)
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report])





  useEffect(() => {
 
   // getProjectDriver(token, props.item.project.id, props.item.id)
    var new_item = report
    /**if(new_item.drive_start_at){
      const hms = new_item.drive_start_at.split(":")
      new_item = new_item.set("drive_start_at", setMinutes(setHours(new_item.worked_date, hms[0]), hms[1]))
    }**/
    if(!new_item.fee_information){
      new_item = new_item.set("fee_information", new ReportDriverFee())
    }else{
      new_item = new_item.set("fee_information", new ReportDriverFee(new_item.fee_information))
    }
    setItem(new_item)
    
    if(report.project && report.project.category ){

        var r = null
        switch(Number(report.project.category)){
          case 1: r =  new ReportCorporate(report.project)
            break
          case 2 : r =  new ReportCharter(report.project)
            break;
          case 3: r =  new ReportAmazon(report.project)
            break
          case 4: r =  new ReportYamato(report.project)
            break
          case 5: r =  new ReportYusei(report.project)
            break
          case 6: r =  new ReportCommition(report.project)
            break
          case 7: r =  new ReportCommitionAndDate(report.project)
            break
          default: r =  new Report()
        }
      
        setReportClass(r)
      }else{
        switch(Number(report.spot_type)){
          case 1 : 
            setReportClass(new ReportSpotCharter())
            break
          case 2 : 
            setReportClass(new ReportSpotPersonal())
            break
          case 3: 
            setReportClass(new ReportSpotCorporate())
              break
          default :
            break
        }
      }
      
     
      //setProjectDriver(report.)
    
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {
    if(item){
      var rec = item.receipts.map(r => {
        if(r.description){
          return(
            <div className="receipt-item">
              <div>{r.description}</div>
              {
                r.description !== "受領証" ?  <div>{r.price.toLocaleString()}円</div> : null
              }
              
              <div className="image"><ImageBox receipt_id={r.id} report_id={report.id} token={token} url={url}></ImageBox></div>
            </div>
          )
        }else{
          return null
        }
      })
      
      const  up_uplaoed = up_uploaded_receipts.map(r => {

        return(
          <div className="receipt-item">
            <div>{r.description}</div>
            <div>{r.price.toLocaleString()}円</div>
            <div className="image"><PreviewBox file={r.file} /></div>
          </div>
        )
      })

      rec = rec.concat(up_uplaoed)
    
      if(profile && profile.user_type !== config.get("USER_PARTNER") ){
        rec.push(<ReceiptBox receipt={new Receipt()} changeHandler={addReceiptHandler}/>)
      }
      
      setReceipts(rec)
    }
  
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[item, up_uploaded_receipts_updated_at])


  useEffect(() => {
    getReport(token, report.id)
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[receipt_created])
  
  useEffect(() => {
    
    if(item && updated_report &&  updated_report.receipts && updated_report.receipts.length > 0){
      const new_item = item.set("receipts", updated_report.receipts)
      setItem(new_item)
    }
      
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updated_report])


  const addReceiptHandler  = (receipt) => {
    const new_array = up_uploaded_receipts
    new_array.push(receipt)
    setUnUploadedReceipts(new_array)
    setUnUploadedReceiptsUpdatedAt(new Date())
  }


  const changeHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
  } 
  
  const changeDateHandler = (e) => {
    
    const value = new Date(e.target.value)
    const start_date = setDate(setMonth(setYear(item.work_start_at, value.getFullYear()),(value.getMonth()) ), value.getDate() ) 
    var new_item = item.set("work_start_at", start_date)

    const end_date = setDate(setMonth(setYear(item.work_end_at, value.getFullYear()),(value.getMonth()) ), value.getDate() ) 
    new_item = new_item.set("work_end_at", end_date)
    setItem(new_item)
  }
  

  const changeEndDateHandler = (e) => {
    const value = new Date(e.target.value)
    const end_date = setDate(setMonth(setYear(item.work_end_at, value.getFullYear()),(value.getMonth()) ), value.getDate() ) 
    const new_item = item.set("work_end_at", end_date)
    setItem(new_item)
  }

  /**

  const changeHandlerIs = (e) => {
    if(item.is_worked === 0){
      setItem(item.set("is_worked", 1))
    }else{
      setItem(item.set("is_worked", 0))
    }
   
  }  
   */ 

  const deleteHandler = () => {
      updateHandler(item ,"delete")
      closeHandler()
  }

  const closeDeleteWindowHandler = () => {
    setDelelteWindow(null)
  }

  const update = () => {
    if(is_delete){
      setDelelteWindow(<DeleteWindow message="日報を削除してよろしいですか。" executeHandler={ deleteHandler } closeHandler={closeDeleteWindowHandler} />)
    }else{
      if(up_uploaded_receipts.length > 0){
        createReceipt(report.id, up_uploaded_receipts)
      }else{
        updateHandler(item)
      } 
      closeHandler()
    }
   
  }

  const statusChangeHandler = (e) => {
    const target = e.target
    
    switch(target.value) {
      case "uncheck" : 
        var new_item = item.set("checked_at", null)
        new_item = new_item.set("is_return", 0)
        setItem(new_item)
        setIsDelete(false)
        break
      case "pending" : 
        var new_item1 = item.set("checked_at", null)
        new_item1 = new_item1.set("is_return", 2)
        setItem(new_item1)
        setIsDelete(false)
        break
      case "checked" : 
        var new_item2 = item.set("checked_at", new Date())
        new_item2 = new_item2.set("is_return", 0)
        setItem(new_item2)
        setIsDelete(false)
        break
      case "delete" :
        setIsDelete(true)
        break
      default:
        break
    }
  }

  const btnClickHandler = () => {
    window.location.href = ('/project/' +  report.project.id + '/driver/' + project_driver.id)
  }

  const btnClickProjctHandler = () => {
    window.location.href = ('/project/' +  report.project.id )
  }

  const getMontylyReportUrl = () => {

    if(project_driver && report && report.project.category !== 0){
      return <a href={"/project/" + report.project.uid + "/monthly_report/" + report.project_driver_id + "/" + report.getTargetReportYear(project_driver) + "/" + report.getTargetReportMonth(project_driver) } rel="noreferrer" >この案件の月報 <i className="fa-solid fa-arrow-right"></i></a> 
    } else if(report && report.project.category === 0 ){
      return <a href={"/project/" + report.project.uid + "/monthly_report/" + report.project_driver_id + "/" + report.getTargetReportYear(project_driver) + "/" + report.getTargetReportMonth(project_driver) } rel="noreferrer"  >この案件の月報 <i className="fa-solid fa-arrow-right"></i></a>
    } else if(report && report.driver && report.driver.company_driver === 1){
      return <a href={"/project/" + report.project.uid + "/monthly_report/driver/" + report.driver.uid + "/" + report.getTargetReportYear(project_driver) + "/" + report.getTargetReportMonth(project_driver)}>この案件の月報 <i className="fa-solid fa-arrow-right"></i></a>
    } else {
      return null
    }
  
  }

  const getMontylyPaymentUrl = () => {

    if(project_driver && report && report.project.category !== 0 && report.driver.company_driver !== 1){
      return  <a href={"/monthly_payment/" + project_driver.driver.partner.id + "/" + report.getTargetReportYear(project_driver) + "/" + report.getTargetReportMonth(project_driver) + "/e" }  rel="noreferrer" >この案件の支払い明細 <i className="fa-solid fa-arrow-right"></i></a>
    } else if(report && report.project.category === 0 && report.driver.company_driver !== 1 ){
      return <a href={"/monthly_payment/" + report.project.sport_partner_id + "/" + report.getTargetReportYear(project_driver) + "/" + report.getTargetReportMonth(project_driver) + "/e" }  rel="noreferrer" >この案件の支払い明細 <i className="fa-solid fa-arrow-right"></i></a> 
    } else if(report && report.driver && report.driver.company_driver === 1){
      return <a href={"/monthly_payment/1/" + report.getTargetReportYear(project_driver) + "/" + report.getTargetReportMonth(project_driver) + "/driver/" + report.driver.id + "/e"}>この案件の支払い明細 <i className="fa-solid fa-arrow-right"></i></a>
    } else {
      return null
    }
  
  }


  const copyText = () => {
  
    const text = item.getText(report_class,report.project, project_driver,report.driver)
    navigator.clipboard.writeText(text).then(  () => {}, () => {});
    setTextMessage(<span className="copyied">コピーしました。</span>)
  }

  return (
    <div className="modal-window" data-id="modal1" >
 
    <button className="js-close button-close" onClick={closeHandler}>Close</button>
        <div className="modal-scroll__container">
        <div className="column-detail">
            <div className="column-left">
              
                <div className="column">
                
                  <ReportDetail driver={new Driver(report.driver)} is_editable={profile && profile.user_type !== config.get("USER_PARTNER")}  report={report_class} item={item} changeHandler={changeHandler} changeDateHandler={changeDateHandler} changeEndDateHandler={changeEndDateHandler}/>
                    
                </div>
               
                <div className="receipts">
                 <div className="label">立替</div> <div>{receipts}</div>
                </div>
                {profile && profile.user_type !== config.get("USER_PARTNER") ?
                <div className="status_select">
                  <p>ステータス</p>
                 
                  <select name="" onChange={statusChangeHandler}>
                    <option value="uncheck" selected={item && item.checked_at === null}>確認待ち</option>
                    <option value="pending" selected={item && item.is_return === 2}>保留</option>
                    <option value="checked" selected={item && item.checked_at !== null && item.is_return !== 2}>確認済み</option>
                    <option value="delete">削除</option>
                  </select>
                  
                </div>  
                : null}
              
             

            </div>
            { profile && profile.user_type !== config.get("USER_PARTNER") ? 

            
              <div className="column-right">
              
              {report.project.project_type === 1 ?
              <div>
                <h4>案件</h4>
                <ProjectDetailItem project={report.project} clients={[]} client={report.project.client} is_edit={false} formats={report_format_list}  />
         
                { profile && profile.user_type === config.get("USER_FINANCE") ? 
                    <div className="button_wrap center">
                      <button className="red" type="button" onClick={btnClickProjctHandler}>編集</button>
                    </div>
                    : null
                }

                <h4>稼働条件</h4>
                {project_driver ? <ProjectDriverDetailItem 
                        projectObject={null}
                        report_url={null}
                        item={project_driver} 
                        is_edit={false}
                      />
                      : null}
                { profile && profile.user_type === config.get("USER_FINANCE") ? 
                    <div className="button_wrap center">
                      <button className="red" type="button" onClick={btnClickHandler}>編集</button>
                    </div>
                    : null
                }
              </div>
              :
              <div>
                <h4>依頼先詳細</h4>
                <PartnerDetailItem partner={partner} is_edit={false} />
              </div>
              }
              <h4>月報</h4>
              <div className="link">{

                getMontylyReportUrl()
              }
              </div>

              <h4>支払い明細</h4>
              <div className="link">{
                getMontylyPaymentUrl()
              }
              </div>
          </div>
         : null }
        </div>
        

        { profile && profile.user_type !== config.get("USER_PARTNER") ? 
          <div className="button_wrap center">
             

            <button className="red" type="button" onClick={update}>更新</button>
            <div className="a icon_button link copy" onClick={copyText}>■ テキストをコピー {textMessage}</div>
          </div>
          :null}
        </div>
        {delete_window }
  </div>
  );
};