
import DriverProps from './DriverProps'
import { addMonths, format, setDate,endOfMonth } from 'date-fns';

const props = extendValues => class extends DriverProps {};
class Driver extends props({}) {

  validate() {
    const errors = []
    if(!this.name || this.name === "") errors.push("ドライバー名が未入力です。")
    if(!this.kana || this.kana === "") errors.push("フリガナが未入力です。")
    if(this.kana &&  !this.kana.match(/^[ァ-ヶー　]+$/)) errors.push("フリガナは全角カタカナで入力してください。")

    if(!this.user_id || this.user_id === "") errors.push("担当マネージャーが未入力です。")
    if(!this.partner_id || this.partner_id === "") errors.push("所属企業が未入力です。")
    
    if(!this.tel || this.tel === "") errors.push("電話番号が未入力です。")
    if(this.tel && !this.tel.match(/^[0-9]+$/))  errors.push("電話番号は半角数字で入力してください。")
    return errors
  }

  getPaymentExecuteDate(target_date) {

    var pay_date = null 
    /**switch(this.payment_day_type) {
     case 1 : pay_date = setDate(addMonths(target_date,1),10)
       break
     case 2 : pay_date = setDate(addMonths(target_date,1),15)
       break
     case 3 : pay_date = setDate(addMonths(target_date,1),20)
       break
     case 4 : pay_date = setDate(addMonths(target_date,1),25)
       break
     case 5 : pay_date = endOfMonth(addMonths(target_date,1))
       break
     case 6 : pay_date = setDate(addMonths(target_date,2),10)
       break
     case 7 : pay_date = setDate(addMonths(target_date,2),15)
       break
     case 8 : pay_date = setDate(addMonths(target_date,2),20)
       break
     case 9 : pay_date = setDate(addMonths(target_date,2),25)
       break
     case 10 : pay_date = endOfMonth(addMonths(target_date,2))
       break
     default: {}
    }
    **/

    if(this.payment_day_day && this.payment_day_month){
      if(this.payment_day_day === 31){
        pay_date = endOfMonth(addMonths(target_date,this.payment_day_month))
      }else{
        pay_date = setDate(addMonths(target_date,this.payment_day_month), this.payment_day_day)
      }
      return pay_date ? format(pay_date, "yyyy年M月d日") : "-"
    }else{
      return "-"
    }
     
   }

  

}
export default Driver
