import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import useClientState from '../../../components/client/useClientState';
import Utile from '../../../utiles/Utile';

interface ClientSearchBoxProps {

    token:string,
    defaultValue:Number,
  /**
    * changeHandler
    */
   changeHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const ClientSearchBox = ({
  token,
  defaultValue,
  changeHandler,
  ...props
}: ClientSearchBoxProps) => { 

  const [managers, setManagers] = useState(null)
  const [default_value, setDefaultValue] = useState(null)
  
  const {client_list, getClientList} = useClientState()

  useEffect(() => {
   
    if(defaultValue){
      getClientList(token,1,{id:defaultValue})
    }else{
      getClientList(token, 1)
    }

    getClientList(token, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token,defaultValue])


  useEffect(() => {
    if(client_list){
      const _list = [{value:null, label:"選択してください"}]
      setManagers(_list.concat(client_list.map(item => {
        return {value:Number(item.id), label:item.name}
      })).filter(item => item !== null))
  
      const def = client_list.filter(item => item.id ===  Number(defaultValue))
      if(def.length > 0){
        const def_val = {value:Number(def[0].id), label:def[0].name}
        setDefaultValue(def_val)
      }
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client_list])



  const onChangeHandler = (e) => {

    changeHandler(e)
    setDefaultValue(e)
  }

  const onInputChangeHandler = (e) => {
    if(e){
      getClientList(token,1,{name:e})
    } 
    
  }

  return (


    <Select
    placeholder= "選択してください"
    options={managers}
    value = {default_value}
    defaultValue = {default_value}
    onInputChange={onInputChangeHandler}
    
    noOptionsMessage={()=>""}
    onChange={onChangeHandler}
    styles={Utile.getDefaultSelectStyle()}
    />
  
  );
};

