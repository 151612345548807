import React, {useState} from 'react';
import { Record } from 'immutable'
import DriverProps from '../../../components/drivers/DriverProps';
import { ParnterSearchBox } from '../partner_search/ParnterSearchBox';
import { ManagerSearchBox } from '../manager_search/ManagerSearchBox';

interface SearchDriverProps {
   /**
   * content
   */
   token:String,

   /**
    * Form 
    */
   data:?Record,

   /**
    * SearchBtnClickHandler
    */
   btnClickHandler:Function,

   clearHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const SearchDriver = ({
  //current_page,
  token,
  content,
  data,
  btnClickHandler,
  clearHandler,
  ...props
}: SearchDriverProps) => { 

  const [form_css, setFormCss] = useState(null)
  const [form_active, setFormActive] = useState("active")
  const [item, setItem]= useState(new DriverProps())
  const [with_deleted, setWithDeleted] = useState(null)



  const toggleSearch = () => {
    if(form_css){
      setFormCss(null)
      setFormActive("active")
    }else{
      setFormCss("search-hidden")
      setFormActive(null)
    }
  }

  const onChangHandler = (e) => {
    const target = e.target 
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
  }

  const clickHandler = () => {
    btnClickHandler(item, with_deleted)
  }


  const onChangePartnerSelectHandler = (e) => {
    const new_item = item.set("partner_id", Number(e.value) )
    setItem(new_item)
  }

  const onChangeManagerSelectHandler = (e) => {
    const new_item = item.set("user_id", Number(e.value) )
    setItem(new_item)
  }


  const onChangWithDeletedHandler = () => {
    setWithDeleted(with_deleted === 1 ? 0 : 1)
  }

  const kedownHandler = (e) => {
    if (e.key === 'Enter') {
      clickHandler()
    }
  }
  return (
    <div className="container more_search">
      <div className="column">
      <p id="more_search_ttl" className={form_active} onClick={toggleSearch}>絞り込み検索</p>
      <form className={form_css} onKeyDown={kedownHandler}>
        <div>
          <div >
           

          <div>
                <div className="input_wrap">
                    
                    <label className="">
                      <span className="label_name">ドライバー名</span>
                      <input type="text"  name="name" onChange={onChangHandler} />
                    </label>
                    <label className="">
                      <span className="label_name">所属企業</span>
                      <ParnterSearchBox changeHandler={onChangePartnerSelectHandler} token={token} no_mycompany={true}/>
                    </label>

                    <label className="">
                      <span className="label_name">マネージャー</span>
                      <ManagerSearchBox changeHandler={onChangeManagerSelectHandler} token={token} />
                    </label>
                    

                      </div>
                      <div className="input_wrap">
                    <label className="">
                      <span className="label_name">電話番号</span>
                      <input type="text"  name="tel" onChange={onChangHandler}/>
                    </label>

                    <label className="">
                      <span className="label_name">車両番号</span>
                      <input type="text" name="car_no"  onChange={onChangHandler}/>
                    </label>

                    <label className="">
                      <span className="label_name">削除済みドライバーを表示</span>
                      <input type="checkbox" name="with_deleted" checked={with_deleted === 1}  onChange={onChangWithDeletedHandler}/>　
                    </label>
                  </div>
              </div>


          </div>
          <div className="input_wrap"></div>
          <div className="button_wrap center">
            
            <button type="button" onClick={clearHandler}>クリア</button>
            <input type="button" value="検索" className="button red" onClick={clickHandler} />
          </div>
        </div>
        
      </form>
    </div>
  </div>
  );
};


/**
 * 

           
 */