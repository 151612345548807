import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import useUserState from '../../../components/user/useUserState';
import Utile from '../../../utiles/Utile';

interface UserSearchBoxProps {
    token:string,
    defaultValue:Number,
    changeHandler:Function
}

export const UserSearchBox = ({
  token,
  defaultValue,
  changeHandler,
  ...props
}: UserSearchBoxProps) => { 

  const [users, setUsers] = useState(null)
  const {user_list, list_updated_at, getUserList} = useUserState()
  const [default_value, setDefaultValue] = useState(null)
  
  useEffect(() => {
    if(token){
      getUserList(token,1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])


  useEffect(() => {
    if(user_list){
      const _list = [{value:null, label:"選択してください"}]
      setUsers(_list.concat(user_list.map(item => {
        return {value:item.id, label:item.name}
      }).filter(item => item !== null)))
  
      const def = user_list.filter(item => item.id ===  Number(defaultValue))
      if(def.length > 0){
        const def_val = {value:Number(def[0].id), label:def[0].name}
        setDefaultValue(def_val)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])


  const onChangeHandler = (e) => {
    changeHandler(e)
    setDefaultValue(e)
  }


  return (
    <Select
    placeholder= "選択してください"
    options={users}
    defaultValue = {default_value}
    value = {default_value}
    noOptionsMessage={()=>""}
    onChange={onChangeHandler}
    styles={Utile.getDefaultSelectStyle(200)}
    />
  );
};

