import { Record } from 'immutable'


const ClientProps = Record({
  id:null,
  name:null,
  kana:null,
  contract_url:null,
  name_in_charge:null,
  kana_in_charge:null,
  closing_date:null,
  fee_rate:null,
  payment_day_type:null,
  prefecture:null,
  address:null,
  tel:null,
  email:null,
  note:null,
  closing_date_day:null,
  payment_day_day:null,
  payment_day_month:null

});
export default ClientProps;

