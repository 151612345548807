import React, { useEffect, useState } from 'react'

import { format, parseISO,addDays,isSameDay } from 'date-fns';
import "./logi.css"
import Report from "../../report/Report"

function BodyLogi(props:PropsValue) {
 
  const [list, setList] = useState(null)
  const [out_list, setOutList] = useState(null)
  const [repot_list , setReportList] = useState([])
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())


  useEffect(() => {
    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ props.repot_list, props.repo_list_updated_at])

  useEffect(() => {
    
    if(repot_list ){
      
        var distance_total = 0

        var over_time_hour = 0
        var over_distance = 0
        const working_counter =  repot_list.map(item => {
          return item.isInTargetMonth(props.year, props.month)
        }).filter(item => item === true).length
        const report = new Report(props.project)
      
        const _list = new Array(props.days + 1).fill(0).map((d,index) => {
   
          const target_day = addDays(props.start_date, index)
       
          const item = repot_list.find(item => isSameDay(parseISO(item.worked_date),target_day ))
          

          if(item){
            over_time_hour = report.getOverTimeHour(parseISO(item.work_start_at), parseISO(item.work_end_at), item.fee_information ? item.fee_information.over_time : 0)
            over_distance = report.getOverDistance(item.distance,item.fee_information ? item.fee_information.over_distance : 0)
      
          }
          
          const tatekae = item ? item.getTatekaeTotal() : 0
          distance_total +=  item ? item.distance : 0
          
         
          return(
            <tr className={item ? "link" : null} >
               {props.is_public ||!props.has_edit_right ? null : <td>{item ? <input type="checkbox" className="sml" checked={props.selected_list.find( s => (item && s === item.id)) ? true : false} onClick={() => props.checkHandler(item.id)}/> : null}</td>}
               <td onClick={() => item? props.changeHandler(item.id) :null}>{format(target_day, "dd")}</td>
               <td onClick={() => item? props.changeHandler(item.id) :null}>{item ? "■" : "□"}</td>
              
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{format(parseISO(item.work_start_at), "HH:mm")}</td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{format(parseISO(item.work_end_at), "HH:mm")}</td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.distance}<span className="km">km</span></td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{over_time_hour ? report.getOverTimeHourString(over_time_hour) +"/": null }{over_distance ? over_distance + "km/" : null}{tatekae ? tatekae + "円" : null}</td>): <td></td>}
              <td></td>
              <td></td>
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{Number(item.requested_unit ? item.requested_unit : 0) - Number(item.return_requested_unit ? item.return_requested_unit : 0) }<span className="yen_mark" >件</span></td>) : <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.meter_start}</td>) : <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.meter_end}</td>): <td></td>}
            </tr>
          )
        })
      _list.push(
        <tr>
           <td colspan={props.is_public || !props.has_edit_right ?  3 : 4}  className="report_subtotal">月度稼動日数　走行距離　合計</td><td>{working_counter}<span className="km">日</span></td><td>{distance_total}<span className="km">km</span></td><td colspan={props.is_public || !props.has_edit_right ?  5 : 6}></td>
        </tr>
      )
      setList(_list)

      if(!props.is_public){
        var fee_total = 0
        var fee_real_total = 0
        
   
        const _out_list = new Array(props.days + 1).fill(0).map((d,index) => {
          const target_day = addDays(props.start_date, index)
          const item = repot_list.find(item => isSameDay(parseISO(item.worked_date),target_day ))
          var fee = 0
          var fee_real = 0 

          if(item){
            
            fee = item.fee ? item.fee : 0
            //fee_real = item.fee_information ? Number(project_driver.fee_information.unit_price) + Number(item.fee_information.unit_price ):Number(project_driver.fee_information.unit_price)
            fee_real = item.getRealFee()
            fee_total += fee
            fee_real_total += Number(fee_real ? fee_real : 0)
          }

          return <tr><td>{fee.toLocaleString()}</td><td>{fee_real ? fee_real.toLocaleString() : 0}</td></tr>
        })

        _out_list.push(
          <tr>
             <td >{fee_total.toLocaleString()}</td><td >{fee_real_total ? fee_real_total.toLocaleString() :null }</td>
          </tr>
        )
        setOutList(_out_list)
      }
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at,props.selected_at])



  return(
    <div className="tablewrap">
    {!props.is_public  ?
      <table className="logi-out">
        <tbody>
        <tr>
       
        <th colSpan="2">　</th>
        </tr>
        <tr>
          <th>報告運賃</th>
          <th>支払単価</th> 
        </tr>
        {out_list}
        </tbody>
      </table>
    : null}
    <table className="logi">
      <tbody>
      <tr>
        <th colSpan="8">※　各締日にご担当者様の検印を頂いて下さい</th><th colSpan="3"></th>
      </tr>
      <tr>
      {props.is_public || !props.has_edit_right ? null : <th><input type="checkbox" onChange={props.checkAll}/></th>}<th>日付</th><th>稼動</th><th>開始時間</th><th>終了時間</th><th>走行距離</th><th>備考欄(立替等)</th><th>検印</th><th>週払</th><th>配達件数</th><th>開始メーター</th><th>終了メーター</th>
      </tr>
      {list}
     
      </tbody>
    </table>
    <div className="example_box">
    <div className="inleft">
      <p className="bold">記入例</p>
      <table>
        <tr>
          
          <th>日付</th>
          <th>稼動</th>
          <th colspan="5">開始時間</th>
          <th colspan="5">終了時間</th>
          <th colspan="4">走行距離</th>
        </tr>
        <tr>
          <td>1</td>
          <td>□</td>
          <td></td>
          <td></td>
          <td>:</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>:</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>㎞</td>
        </tr>
        <tr>
          <td>2</td>
          <td>■</td>
          <td></td>
          <td><i>９</i></td>
          <td>:</td>
          <td><i>０</i></td>
          <td><i>０</i></td>
          <td><i>１</i></td>
          <td><i>８</i></td>
          <td>:</td>
          <td><i>３</i></td>
          <td><i>４</i></td>
          <td></td>
          <td><i>８</i></td>
          <td><i>０</i></td>
          <td>㎞</td>
        </tr>
      </table>
    </div>
    <div className="inright">
      <dl>
        <dt>※</dt>
        <dd>稼動日に該当する場合のみ□を塗りつぶしてください<br />
  時間と距離欄は１つの枠に数字１桁で記載してください</dd>
      </dl>
      <dl>
        <dt>※</dt>
        <dd>高速、駐車場代合計を備考欄に記入。</dd>
      </dl>
    </div>
    <p>※&nbsp;本稼動報告書は稼動先１件につき１枚必要となります。複数の稼動先で仕事をされている方は、お手数ですが必要枚数分ご記入願います。</p>
    <p>※&nbsp;該当月度の稼動日数が確定した時点で「月度稼動日数合計欄」に稼動日数を記入して、FAXをご送付ください。</p>
  </div>
  </div>

  )
}

export default BodyLogi

