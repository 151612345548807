
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Action from '../../../action/Action';

const ReportFormatAction = {


  getList(token,  callback, errorHandler){
    
    const url = config.get("API_PATH") +  "/report_formats/"
    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },
  
 
 
}

export default ReportFormatAction
