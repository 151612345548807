import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import Utile from '../../utiles/Utile';

function ClientDetailItem(props:PropsValue) {

  const [item, setItem] = useState(null)

  
  useEffect(() => {
    setItem(props.client)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.client])
  

  const changeHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value.trim())    
    setItem(new_item)
    props.changeHandler(new_item)
  } 

  const changeNumberHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, Number(target.value.trim()))
    setItem(new_item)
    props.changeHandler(new_item)
  } 


  return(
    <table className="input_table col12 login_tbl">
		  <tbody>
			<tr>
				<th className="tl">クライアント名 *</th>
				<td>
          {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="株式会社α" name="name" value={item ? item.name : undefined}  onChange={changeHandler} />
          :item ? item.name : null}
        </td>
			</tr>
			<tr>
				<th className="tl">フリガナ *</th>
				<td>
        {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="カブシキカイシャアルファ" name="kana" value={item ? item.kana : undefined}  onChange={changeHandler}/>
        :item ? item.kana : null}
        </td>
			</tr>
			<tr>
				<th className="tl">都道府県 *</th>
				<td>
        {props.is_edit ? 
          <select name="prefecture" value={item ? item.prefecture : undefined}  onChange={changeHandler}>
                              <option value="" selected="">都道府県</option>
                              {
                                config.get("PREFS").map(item => {
                                  return(<option value={item.value}>{item.label}</option>)
                                })
                              }
          </select>
				:item ? Utile.getLabelByValue(config.get("PREFS"),Number(item.prefecture))  : null}
        </td>
			</tr>
			<tr>
				<th className="tl">所在地 *</th>
				<td>
        {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="" name="address" value={item ? item.address : undefined}  onChange={changeHandler} />
          :item ? item.address : null}
        </td>
			</tr>
			<tr>
				<th className="tl">電話番号 *</th>
				<td>
        {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="" name="tel" value={item ? item.tel : undefined}  onChange={changeHandler}/>
        :item ? item.tel : null}
        </td>
			</tr>
			<tr>
				<th className="tl">メールアドレス *</th>
				<td>
        {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="abc@hound-japan.com" name="email" value={item ? item.email : undefined}  onChange={changeHandler}/>
        :item ? item.email : null}
        </td>
			</tr>
                        <tr>
				<th className="tl">契約書URL </th>
				<td>
        {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" name="contract_url" value={item ? item.contract_url : undefined}  onChange={changeHandler} />
        :item ? item.contract_url : null}
        {item && item.id ?<div><a href={item.contract_url} >ファイルを開く</a> </div>: null}
        </td>
			</tr>
      <tr>
				<th className="tl">担当者名 *</th>
				<td>
        {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="担当　太郎" name="name_in_charge" value={item ? item.name_in_charge : undefined}  onChange={changeHandler}/>
        :item ? item.name_in_charge : null}
        </td>
			</tr>
			<tr>
				<th className="tl">担当者フリガナ *</th>
				<td>
        {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="タントウ　タロウ" name="kana_in_charge" value={item ? item.kana_in_charge : undefined}  onChange={changeHandler} />
        :item ? item.kana_in_charge : null}
        </td>
			</tr>
			<tr>
				<th className="tl">締め日 *</th>
				<td>
          {props.is_edit ? 
            <select name="closing_date_day" value={item ? item.closing_date_day : undefined}  onChange={changeNumberHandler} >
              <option value="" selected="">選択してください</option>
                            {
                              config.get("PAYMENT_TYPE_DAY").map(_item => {
                                return(<option value={_item.value}>{_item.label}</option>)
                              })
                              }
                              </select>
                              :item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.closing_date_day)) : null

                            }
				</td>
			</tr>
			<tr>
				<th className="tl">請求手数料 *</th>
				<td>
        {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="7.5" name="fee_rate" value={item ? item.fee_rate : undefined}  onChange={changeHandler} />
        
         :item ? item.fee_rate : null}
				&nbsp;%</td>
			</tr>
			<tr>
				<th className="tl">支払日 *</th>
				<td>
        {
                                props.is_edit ? 
                                <select name="payment_day_month" value={item ? item.payment_day_month : undefined}  onChange={changeNumberHandler} >
                                  <option value="" selected="">選択してください</option>
                                  {
                                    config.get("PAYMENT_TYPE_MONTH").map(_item => {
                                      return(<option value={_item.value}>{_item.label}</option>)
                                    })
                                }
                                </select>
                                :item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_MONTH"),Number(item.payment_day_month)) : null
                            }
                            &nbsp;
                            {props.is_edit ? 
                            
                            <select name="payment_day_day" value={item ? item.payment_day_day : undefined}  onChange={changeNumberHandler} >
                            <option value="" selected="">選択してください</option>
                            {
                              config.get("PAYMENT_TYPE_DAY").map(_item => {
                                return(<option value={_item.value}>{_item.label}</option>)
                              })
                              }
                              </select>
                              :item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.payment_day_day)) : null

                            }
				</td>
			</tr>
			<tr>
				<th className="tl">備考</th>
				<td>
        {props.is_edit ? 
          <textarea type="text" className="col12" rows="5" name="note" value={item ? item.note : undefined}  onChange={changeHandler} />
          :item ? item.note : null}
        </td>
			</tr>
		</tbody>
</table>
  )
}

export default ClientDetailItem



/**
 *   <select name="fee_rate" value={item ? item.fee_rate : undefined}  onChange={changeHandler}>
						<option value="" selected="">選択してください</option>
						{
                                config.get("CLIENT_COMMISION").map(item => {
                                  return(<option value={item.value}>{item.label}</option>)
                                })
                              }
					</select>
 */