import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import Utile from '../../utiles/Utile';
import BankInfo from '../bankinfo/BankInfo';
function PartnerDetailItem(props:PropsValue) {

  const [item, setItem] = useState(null)

  
  useEffect(() => {
 
    setItem(props.partner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.partner])
  

  const changeHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value.trim())    
    setItem(new_item)
    props.changeHandler(new_item)
  } 

  const changeBankHandler = (e) => {
    const target = e.target 
    const new_item = item.setIn(["bank_info", target.name], target.value )
    setItem(new_item)
    props.changeHandler(new_item)
  }
  
  const changeNumberHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, Number(target.value.trim()))
    setItem(new_item)
    props.changeHandler(new_item)
  } 


  return(
    <table className="input_table col12 login_tbl">
											<tbody>
												<tr>
													<th className="tl">依頼先会社名 *</th>
													<td>
                            {
                              props.is_edit ? 
                              <input autocomplete="off"  type="text" className="col9" placeholder="株式会社運送" name="name" value={item ? item.name : undefined} onChange={changeHandler} />
                              :item ? item.name : null
                            }
                          </td>
												</tr>
												<tr>
													<th className="tl">フリガナ *</th>
													<td>
                          {
                              props.is_edit ? 
                              <input autocomplete="off"  type="text" className="col9" placeholder="カブシキカイシャウンソウ" name="kana"  value={item ? item.kana : undefined}  onChange={changeHandler} />
                              :item ? item.kana : null
                          }
                          </td>
												</tr>
												<tr>
													<th className="tl">都道府県 *</th>
													<td>
                           {
                              props.is_edit ? 
                              <select name="prefecture" value={item ? item.prefecture : undefined}  onChange={changeHandler} >
                              <option value="" selected="">都道府県</option>
                              <option value="1">北海道</option>
                              <option value="2">青森県</option>
                              <option value="3">岩手県</option>
                              <option value="4">宮城県</option>
                              <option value="5">秋田県</option>
                              <option value="6">山形県</option>
                              <option value="7">福島県</option>
                              <option value="8">茨城県</option>
                              <option value="9">栃木県</option>
                              <option value="10">群馬県</option>
                              <option value="11">埼玉県</option>
                              <option value="12">千葉県</option>
                              <option value="13">東京都</option>
                              <option value="14">神奈川県</option>
                              <option value="15">新潟県</option>
                              <option value="16">富山県</option>
                              <option value="17">石川県</option>
                              <option value="18">福井県</option>
                              <option value="19">山梨県</option>
                              <option value="20">長野県</option>
                              <option value="21">岐阜県</option>
                              <option value="22">静岡県</option>
                              <option value="23">愛知県</option>
                              <option value="24">三重県</option>
                              <option value="25">滋賀県</option>
                              <option value="26">京都府</option>
                              <option value="27">大阪府</option>
                              <option value="28">兵庫県</option>
                              <option value="29">奈良県</option>
                              <option value="30">和歌山県</option>
                              <option value="31">鳥取県</option>
                              <option value="32">島根県</option>
                              <option value="33">岡山県</option>
                              <option value="34">広島県</option>
                              <option value="35">山口県</option>
                              <option value="36">徳島県</option>
                              <option value="37">香川県</option>
                              <option value="38">愛媛県</option>
                              <option value="39">高知県</option>
                              <option value="40">福岡県</option>
                              <option value="41">佐賀県</option>
                              <option value="42">長崎県</option>
                              <option value="43">熊本県</option>
                              <option value="44">大分県</option>
                              <option value="45">宮崎県</option>
                              <option value="46">鹿児島県</option>
                              <option value="47">沖縄県</option>
                          </select>
                            : item ? Utile.getLabelByValue(config.get("PREFS"),Number(item.prefecture)) : null
                            }

                            
													</td>
												</tr>
												<tr>
													<th className="tl">所在地 *</th>
													<td>
                            {
                                props.is_edit ? 
                                <input autocomplete="off"  type="text" className="col9" placeholder="" name="address" value={item ? item.address : undefined}  onChange={changeHandler} />
                                :item ? item.address : null
                            }
                            
                          </td>
												</tr>
												<tr>
													<th className="tl">電話番号 *</th>
													<td>
                            {
                                props.is_edit ? 
                                <input autocomplete="off"  type="text" className="col9" placeholder="" name="tel" value={item ? item.tel : undefined}  onChange={changeHandler} />
                                :item ? item.tel : null
                            }
                          </td>
												</tr>
												<tr>
													<th className="tl">メールアドレス *</th>
													<td>
                            {
                                props.is_edit ? 
                                <input autocomplete="off"  type="text" className="col9" placeholder="abc@hound-japan.com" name="email" value={item ? item.email : undefined}  onChange={changeHandler} />
                                :item ? item.email : null
                            }
                            
                          </td>
												</tr>
                        <tr>
													<th className="tl">パスワード *</th>
													<td>
                            {
                                props.is_edit ? 
                                <input autocomplete="off"  type="text" className="col9" placeholder="********" name="password" value={item ? item.password : undefined}  onChange={changeHandler} />
                                :"******"
                            }
                          </td>
												</tr>

												<tr>
													<th className="tl">契約書URL </th>
													<td>
                            {
                                props.is_edit ? 
                                <span>
                                <input autocomplete="off"  type="text" className="col9" name="contract_url" value={item ? item.contract_url : undefined}  onChange={changeHandler} />
                                {item && item.id  && item.contract_url ?<div><a href={item.contract_url} >ファイルを開く</a> </div>: null} </span>
                                :item ? <a href={item.contract_url}>ファイルを開く</a>  : null
                            }
                          
                            
                          </td>
												</tr>
												<tr>
													<th className="tl">担当者名 *</th>
													<td>
                            {
                                props.is_edit ? 
                                <input autocomplete="off"  type="text" className="col9" placeholder="運送　太郎" name="name_in_charge" value={item ? item.name_in_charge : undefined}  onChange={changeHandler} />
                                :item ? item.name_in_charge : null
                            }
                          </td>
												</tr>
												<tr>
													<th className="tl">担当者フリガナ *</th>
													<td>
                            {
                                props.is_edit ? 
                                <input autocomplete="off"  type="text" className="col9" placeholder="ウンソウ　タロウ" name="kana_in_charge" value={item ? item.kana_in_charge : undefined}  onChange={changeHandler}/>
                                :item ? item.kana_in_charge : null
                            }
                            
                          </td>
												</tr>
												<tr>
													<th className="tl">振込先情報 *</th>
													<td>
                            {props.is_edit ? 
                            <div>
                              <input autocomplete="off" type="text" className="col9" placeholder="銀行名"  name="bank_name" value={item  && item.bank_info ? item.bank_info.bank_name : undefined}  onChange={changeBankHandler}/>
                              <input autocomplete="off" type="text" className="col9" placeholder="支店名"  name="branch_name" value={item && item.bank_info ? item.bank_info.branch_name : undefined}  onChange={changeBankHandler}/>
                              <select name="account_type" value={item && item.bank_info ? item.bank_info.account_type : undefined}    onChange={changeBankHandler}>
                                  <option value="" selected="">選択してください</option>
                                    {
                                      config.get("ACCOUNT_TYPE").map(item => {
                                        return (<option value={item.value}>{item.label}</option>)
                                      })
                                    }
                                  </select>
                                  <input autocomplete="off" type="text" className="col9" placeholder="口座名"  name="account_name" value={item && item.bank_info ? item.bank_info.account_name : undefined}  onChange={changeBankHandler}/>
                                  <input autocomplete="off" type="text" className="col9" placeholder="口座番号"  name="account_id" value={item && item.bank_info ? item.bank_info.account_id : undefined}  onChange={changeBankHandler}/>
                            </div>
                          
                            :item  && item.bank_info ?  (new BankInfo(item.bank_info)).getString()  :null }
                      
                          </td>
												</tr>
												<tr>
													<th className="tl">支払い手数料 *</th>
													<td>
                            {
                                props.is_edit ? 
                                <input autocomplete="off" type="number" step="0.01" onWheel={(e) => e.target.blur()} className="col3" placeholder=""  name="commision" value={item ? item.commision : undefined}  onChange={changeHandler}/> 
                              :item ? item.commision   : null
                            }
                            &nbsp;%
													</td>
												</tr>
												<tr>
                          <th className="tl">支払日 *</th>
                          <td>
                          {
                                props.is_edit ? 
                                <select name="payment_day_month" value={item ? item.payment_day_month : undefined}  onChange={changeNumberHandler} >
                                  <option value="" selected="">選択してください</option>
                                  {
                                    config.get("PAYMENT_TYPE_MONTH").map(_item => {
                                      return(<option value={_item.value}>{_item.label}</option>)
                                    })
                                }
                                </select>
                                :item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_MONTH"),Number(item.payment_day_month)) : null
                            }
                            &nbsp;
                            {props.is_edit ? 
                            
                            <select name="payment_day_day" value={item ? item.payment_day_day : undefined}  onChange={changeNumberHandler} >
                            <option value="" selected="">選択してください</option>
                            {
                              config.get("PAYMENT_TYPE_DAY").map(_item => {
                                return(<option value={_item.value}>{_item.label}</option>)
                              })
                              }
                              </select>
                              :item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.payment_day_day)) : null

                            }
                          </td>
                        </tr>

                        <tr>
													<th className="tl">税区分 *</th>
													<td>

                            {
                                props.is_edit ? 
                                <select name="tax_include" value={item ? item.tax_include: undefined}  onChange={changeHandler} >
                                  <option value="" selected="">選択してください</option>
                                  {
                                    config.get("TAX_INCLUDE").map(item => {
                                      return(<option value={item.value}>{item.label}</option>)
                                    })
                                }
                                </select>
                                :item ? Utile.getLabelByValue(config.get("TAX_INCLUDE"),Number(item.tax_include)) : null
                            }
                            
													</td>
												</tr>

												<tr>
													<th className="tl">インボイス番号</th>
													<td>
                            {
                                props.is_edit ? 
                                <input autocomplete="off" type="text" className="col9" placeholder="Txxxxxxxxxxx"  name="invoice_id" value={item ? item.invoice_id : undefined}  onChange={changeHandler}/>
                                :item ? item.invoice_id : null
                            }
                            </td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td>
                            {
                                props.is_edit ? 
                                <textarea type="text" className="col12" rows="5"  name="note" value={item ? item.note : undefined}  onChange={changeHandler} />
                                :item ? item.note : null
                            }
                            </td>
												</tr>
											</tbody>
										</table>
    
  )
}

export default PartnerDetailItem

