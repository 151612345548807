

interface DummyReportDeletePopupProps {
  id:String,
  closeHandler:Function,
  deleteDummy:Function
}

/**
 * Primary UI component for user interaction
 */
export const DummyReportDeletePopup = ({
  id,
  closeHandler,
  deleteDummy,
  ...props
}: DummyReportDeletePopupProps) => { 








  return (
    <div className="modal-window sml" data-id="modal1" >
 
    <button className="js-close button-close" onClick={closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column-detail flex w-100">
            <div  className="m-5 link" onClick={() => deleteDummy(id)}>対象に戻す</div>
          </div>
        </div>
  </div>
  );
};