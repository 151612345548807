
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../action/Action';
const ClientAction = {


  getList(token, item, offset, limit,  callback, errorHandler){
    const data = new FormData() 
    data.append('limit', limit)
    data.append('offset', offset)

    if(item && item.id && item.id !== "")
      data.append('id', item.id)
    if(item && item.name && item.name !== "")
      data.append('name', item.name)
    if(item && item.tel && item.tel !== "")
      data.append('tel', item.tel)
    if(item && item.prefecture && item.prefecture !== "")
      data.append('prefecture', item.prefecture)
    if(item && item.name_in_charge && item.name_in_charge !== "")
      data.append('name_in_charge', item.name_in_charge)

    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_CLIENT") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },
  

  getDetail(token, id,  callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_CLIENT")  + id  , token, "GET", {}, null,  callback, errorHandler)
  },

  create( token, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_CLIENT") + item.id  : config.get("API_PATH") + config.get("API_CLIENT")
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },

  delete( token, id,  callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_CLIENT") + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

  saveOnCookie(item){
    cookie.save('client_form',item, { path: '/' })
  },

  loadFromCookie( callback, errorHandler){
    const driver =  cookie.load("client_form")
   
    if(driver) {
      callback({data:driver})
    }else{
      errorHandler({"error":"not found"})
    }
  },
  hasCookie(item, callback, errorHandler){
    const client_form =  cookie.load("client_form")
    if(client_form) return true 
    return false
  },
  removeCookie(item, callback, errorHandler){
    cookie.remove('client_form', { path: '/' })
  }
 
 
}

export default ClientAction
