import { Record } from 'immutable'
import ProjectDriverFeeProps from './ProjectDriverFeeProps';
const ProjectDriverProps = Record({
  id:null,
  uid:null,
  partner_id:null,
  manager_id:null,
  driver_id:null,
  start_date:null,
  end_date:null,
  sunday:null,
  monday:null,
  thuesday:null,
  wednesday:null,
  thursday:null,
  friday:null,
  saturday:null,
  condition:null,
  fee_information:new ProjectDriverFeeProps(),
  close_date:null,
  driver:null,
  note:null,
  manager_name:null
});
export default ProjectDriverProps;
