import React, {  } from 'react'



function ClientDetailLinks(props:PropsValue) {


  


  

  return(
    <div className="column-right">
      <h4>月報一覧</h4>
      <div className="item-list">
        <div className="link-list-item"><a  href={"/monthly_report?client_id=" + props.client.id }>月報一覧を表示</a></div>
      </div>
     
    </div>
  
    
  )
}

export default ClientDetailLinks

