

interface DummyReportPopupProps {
  token:String,
  date:String,
  closeHandler:Function,
  createReport:Function,
  createDummy:Function
}

/**
 * Primary UI component for user interaction
 */
export const DummyReportPopup = ({
  token,
  date,
  closeHandler,
  createReport,
  createDummy,
  ...props
}: DummyReportPopupProps) => { 






  return (
    <div className="modal-window sml" data-id="modal1" >
 
    <button className="js-close button-close" onClick={closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column-detail flex w-100">
            <div className="m-5 link" onClick={() => createReport(date)}>日報を作成する</div>
            <div  className="m-5 link" onClick={() => createDummy(date)}>対象外にする</div>
          </div>
        </div>
  </div>
  );
};