import React, {useState, useEffect  } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { PageApp } from '../../../stories/PageApp'
import ProjectDriverDetail from "./ProjectDriverDetail"
import ProjectDriverProps from './ProjectDriverProps';
import useProjectDriverState from './useProjectDriverState';
import useProjectState from '../useProjectState';


function ProjectDriverDetailApp(props:PropsValue) {
  const { project_id } = useParams();
  const { project_driver_id } = useParams();
  const [searchParams] = useSearchParams();
  
  const is_new = searchParams.get("new") ?  searchParams.get("new") : 0 ;
  const [has_cookie] = useState(false)
  const [profile, setProfile] = useState(null)
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)
  
  
  const { project_driver, removeCookie, saveOnCookie, getProjectDriver, hasCookie, loadFromCookie} = useProjectDriverState()
  const {project, getProject} = useProjectState()


  useEffect(() => {
    if(token && profile){
      //setHasCookie(hasCookie())
      getProject(token,project_id)
      if(is_new === 0 && hasCookie(project_driver_id)){
        loadFromCookie(project_driver_id)
      }else{
        if(project_driver_id === "new"){
          setItem(new ProjectDriverProps({manager_id:profile.id}))
        }else{
          getProjectDriver(token, project_id, project_driver_id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token,profile])
    
  



  useEffect(() => {
    if(project_driver){
      if(is_new !== 0 && (project_driver.project_id !== Number(project_id) || project_driver.id !== Number(project_driver_id)) ){
        getProjectDriver(token, project_id, project_driver_id)
      }else{
        removeCookie()
        setItem(new ProjectDriverProps(project_driver))
      } 
       
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_driver])


  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {

    const partner_id  = _item.driver && _item.driver.partner ? _item.driver.partner.id : null
  
    var new_item = _item.set("driver", null)
    if(!new_item.partner_id && partner_id){
      new_item = new_item.set("partner_id",partner_id)
    }

    saveOnCookie(new_item)
    window.setTimeout(function(){
      window.location.href = "/project/" + project_id + "/driver/confirm" 
    }, 500)
    
  }
  const saveOnCookieHandler = (item) => {
    const new_item = item.set("driver", null)
    saveOnCookie(new_item)
   
    window.location.href = "/project"
  }
  
  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  return(
    <div id="home">
      <PageApp content={<ProjectDriverDetail  has_cookie={has_cookie}  profile={profile} token={token} project={project}  project_id={project_id} project_driver={item}  updateHandler={updateHandler} loadFromCookie={loadFromCookie} saveOnCookieHandler={saveOnCookieHandler} />} profileChangeHandler={profileChangeHandler}  authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ProjectDriverDetailApp

