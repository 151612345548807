import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import { Pageing } from '../../stories/components/pageing/Pageing';
import Utile from '../../utiles/Utile';
import ClientProps from './ClientProps';
import { SearchBoxClient } from '../../stories/components/searchbox/SearchBoxClient';
import ClientDetailOverlay from './ClientDetailOverlay';

function ClientList(props:PropsValue) {

  const [list, setList] = useState(null)
  const [total_number, SetTotalNumber] = useState(1)
  const [detail, setDetail] = useState(null)

 



  const clearHandler = () => {
    window.location.href = "/client"
  }

  useEffect(() => {
    setList(
      props.clients.map(item => {
        return(
          <tr key={"item_" + item.id}>
					  <td>{item.id}</td>
						<td>{item.name}</td>
						<td>{item.prefecture ? Utile.getLabelByValue(config.get("PREFS"),Number(item.prefecture)):null}</td>
            <td>{item.name_in_charge}</td>
            <td>{item.tel}</td>
            <td>{item.email}</td>
            <td><a href={item.contract_url} ><i className="fa-solid fa-arrow-up-right-from-square"></i></a></td>
            <td>{item.fee_rate}</td>
            <td>{ Utile.getLabelByValue(config.get("PAYMENT_TYPE_MONTH"),Number(item.payment_day_month))} {Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.payment_day_day))}</td>
            <td>{Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.closing_date_day))}</td>
            <td>
              <div className="buttons">
                <div className="textbutton" onClick={() =>  displayDetail(item)}>詳細確認</div>
              </div>
            </td>
						
					</tr>
        )
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.clients])



  useEffect(() => {
    SetTotalNumber(props.total_number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.total_number])
  
  const btnClickHandler = (e) =>{
    props.search(e)
  }

  
  const closeHandler = () => {
    setDetail(null)
  }
  const displayDetail = (client) => {
    setDetail(client)
  }

  return(
    <div className="inner">
        {detail?  <div id="overlay" className="overlay" onClick={closeHandler}></div> : null}
			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title">クライアント一覧</h2>
                <Breadcrumbs pages={[new LinkProps("クライアント一覧","#")]} />
						</div>
            <a href="/client/new?new=1" className="button red">新規クライアント</a>
            <SearchBoxClient token={props.token} data={new ClientProps()} btnClickHandler={btnClickHandler} clearHandler={clearHandler} />
            <table className="datatable">
							
							<thead>
                <tr>
									<th>No.</th>
									<th>クライアント名</th>
									<th>都道府県</th>
                  <th>担当者</th>
									<th>電話番号</th>
									<th>email</th>
									<th>契約書</th>
                  <th>手数料</th>
                  <th>支払日</th>
                  <th>締日</th>
                  <th>詳細確認</th>
				
								</tr>
							</thead>
							<tbody>
              {list}
							</tbody>
							<tfoot>
								<tr>
									<td colspan="99">
                    <Pageing total_number={total_number} current_page={props.current_page} limit={props.search_limit} />
									</td>
								</tr>
							</tfoot>
						</table>
				</div>
        {detail ? <ClientDetailOverlay token={props.token} profile={props.profile} client={detail} is_edit={false} closeHandler={closeHandler} /> : null}
		</div>

  )
}

export default ClientList


/**
 * 
 */