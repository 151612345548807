import React, { useState, useEffect } from 'react'
import config from 'react-global-configuration';
import Project from './Project';
import ProjectDetailItem from './ProjectDetailItem'
import useClientState from '../client/useClientState'
import ProjectDetailLinks from './ProjectDetailLinks'
import useReportFormatState from '../monthly_report/format/usReportFormatState';
import useProjectState from './useProjectState';
function ProjectDetailOverlay(props:PropsValue) {
  
  const [formats, setFormats] = useState(null)
  const [message, setMessage] = useState(null)
  const {client, getClient} = useClientState()
  const{report_format_list,report_format_list_updated_at, getReportFormatList} = useReportFormatState()

  const {project, getProject} = useProjectState()



  useEffect(() => {
    const client_id = props.project.client_id ? props.project.client_id : props.project.client.id
    getClient(props.token,client_id)
    getReportFormatList(props.token)
    getProject(props.token, props.project.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client])


  useEffect(() => {
    if(report_format_list_updated_at && report_format_list){
      setFormats(report_format_list)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_format_list_updated_at])

  const btnClickHandler = () => {
    window.location.href = "/project/" + props.project.id  + "?new=1"
  }

  const btnDeleteHandler = () => {
    setMessage(null)
    const _project = new Project(project)
    if(_project.hasWorkingDriver()){
      setMessage("稼働中のドライバーがいる場合は削除できません。")
    }else{
      window.location.href = "/project/" + props.project.id + "/delete"
    }
    //window.location.href = "/project/" + props.project.id + "/delete"
  }

  return(
    <div className="modal-window" data-id="modal1" >
    <button className="js-close button-close" onClick={props.closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column-detail">
            <div className="column-left">
              <ProjectDetailItem project={project}  formats={formats} clients={client ? [client] : null} is_edit={false}/>
            </div>
              <ProjectDetailLinks project={project} />
          </div>
          
          { props.profile && props.profile.user_type === config.get("USER_FINANCE") ? 
            <div className="button_wrap center">
              <button className="textbutton" onClick={btnDeleteHandler}>削除</button>
              <button className="red" type="button" onClick={btnClickHandler}>編集</button>
            </div>
            : null
          }
          {message ? <div className="message w-100 m-5 center">{message}</div> : null}
        </div>
  </div>
  
    
  )
}

export default ProjectDetailOverlay   

