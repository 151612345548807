import { Record } from 'immutable'

const ProjectProps = Record({
  id:null,
  name:null,
  client_id:null,
  project_type:1,
  sport_partner_id:null,
  category:null,
  closing_date:null,
  report_type:null,
  overtime_fee_type:null,
  distance_type:null,
  working_time_type:null,
  overtime_start_at:null,
  overtime_close_at:null,

  closing_date_day:null,
  overtime_start:null,
  overtime_unit:null,
  overdistance_start:null,
  unit:null,
  time_set:null,
  time_set_h:null,
  time_set_m:null,
  status:0,
  note:null,
  client:null,
  drivers:null,
  user:null
});
export default ProjectProps;
