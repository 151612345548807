 import React, { useEffect, useState } from 'react'
import { format ,parseISO , isAfter} from 'date-fns';
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import { MemoComponent } from '../../stories/memo/MemoComponent';
import HeaderDefault from './header/HeaderDefault';
import BodyDefault from './body/BodyDefault';
import BodyDeduction from './body/BodyDeduction';
import MontylyPaymentParmProps from './MontylyPaymentParmProps';
import PaymentItem from './PaymentItem';
import "./monthly_payment.css"


function MonthlyPaymentDetail(props:PropsValue) {





  const [params, setParmas] = useState(new MontylyPaymentParmProps())
  const [repot_list , setReportList] = useState([])
  const [comments , setComments] = useState(null)
  const [editable, setEditable] = useState(false)
  const [last_updated_at , setLastUpdatedAt] = useState(null)
  const [return_comment , setReturnComment] = useState(null)
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())
  
  const [uniqueid, setUniqueId] = useState(null)
  const [monthly_report_list, setMonthryReportList] = useState(null)

  const [deduction_list , setDeductionList] = useState(
    ( new Array(3)).fill(0).map(item => {
      return new PaymentItem({is_non_tax:true, is_edit_all:true, is_tax_rate:true})
     })
  )

  const [fixed_list , setFixedList] = useState(
    ( new Array(6)).fill(0).map(item => {
      return new PaymentItem({})
     })
  )

  const [deduction_list_updated_at, seetDeductionListUpdatedAt] = useState(new Date())

  const [header, setHeader] = useState(null)

  const [total_1, setTotal1] = useState(0)
  const [total_2, setTotal2] = useState(0)

  const [histories, setHistories] = useState(null)





  useEffect(() => {
    setLastUpdatedAt(props.last_updated_at)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.last_updated_at])

  useEffect(() => {
    setEditable(props.btn)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.btn])

  //params設定
  useEffect(() => {
    
    if(props.monthly_payment && props.monthly_payment.content && props.monthly_payment.content.params){
   
      setParmas(new MontylyPaymentParmProps({
        highway_cost:props.monthly_payment.content.params.highway_cost,
        parking_cost:props.monthly_payment.content.params.parking_cost,
        other_cost:props.monthly_payment.content.params.other_cost
      }))
    }else if(props.monthly_reports){  
      
      const highway_cost = params && params.highway_cost ? params.highway_cost : props.monthly_reports.reduce(function(sum, element){
 
        return sum + element.highway_cost;
      }, 0);
      const parking_cost = params && params.parking_cost ?  params.parking_cost : props.monthly_reports.reduce(function(sum, element){
        
        return sum + element.parking_cost;
      }, 0);

      const other_cost =params && params.other_cost ? params.other_cost :  props.monthly_reports.reduce(function(sum, element){
    
        return sum + element.other_cost;
      }, 0);
      setParmas(new MontylyPaymentParmProps({highway_cost:highway_cost,parking_cost:parking_cost,other_cost:other_cost}))
      setRepoListUpdatedAt(new Date())  
    }

  

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.monthly_reports, props.monthly_payment, props.updated_at])


  useEffect(() => {

    if(props.partner && props.company){  
      if(props.monthly_payment && props.monthly_payment.content){
          setReportList(props.monthly_payment.content.repot_list)
          setDeductionList(props.monthly_payment.content.deduction_list)
          setRepoListUpdatedAt(new Date())  
          seetDeductionListUpdatedAt(new Date())  
          if(props.monthly_payment.content.fixed_list){
            setFixedList(props.monthly_payment.content.fixed_list)
          }
      }
      
      setUniqueId(getUniquId())
      setHeader(<HeaderDefault  driver={props.driver}  company={props.company} year={props.year} partner={props.partner}  month={props.month} total={total_1 - total_2}   />) 
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.partner,props.company,props.driver, total_1,total_2])

  useEffect(() => {

    if(props.histories && props.histories.length > 0){
      setHistories(
        props.histories.map((history,index) => {
          const h = history 
          return <div className="history-item">{history.updated_by} {format(parseISO(history.created_at), "yyyy年M月d日 HH:mm")}更新 {index !== 0 ? <span className="link" onClick={() => reverseItem(h)}>[この内容を復元する]</span> : null}</div>
        })
      )
      //coments
      
      const comment_list = props.histories.map((history) => {
          return history.comments.map(comment => {
            return <div className="comment-item"><div className="date">{format(parseISO(comment.created_at), "yyyy年M月d日 HH時mm分")}</div> <div>{comment.content}</div></div>
          })
        }).flat()
      
        setComments(comment_list)

     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.histories])


  useEffect(() => {

    if(props.updated_at){
      
      createTableContent(props.monthly_reports)
      setRepoListUpdatedAt(new Date())  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.updated_at])


  useEffect(() => {

    if(repot_list){
      
      const _m_list =  repot_list.filter(item => item.date && item.date !== "" && item.is_self !== true)
          
          const m_list = (_m_list.map(item => {
            return <div className="mlist-item"><a href={item.url} >{item.detail}[{item.driver_name}]月報</a></div>
          }))
          const _m_list_2 =  repot_list.filter(item => item.is_self === true)
          if(_m_list_2.length > 0){
            m_list.push(<div className="mlist-item"><a href={_m_list_2[0].url} >スポット月報</a></div>) 
          }
          setMonthryReportList(m_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[repot_list])
  

  const changeParamHandler = (e, is_title=false) => {
    const target = e.target
    const _p = params.set(target.name, is_title ? target.value : Number(target.value))
    setParmas(_p)
    setRepoListUpdatedAt(new Date())
  }

  const reverseItem = (history) => { 
    props.updateByHistory(history)
  }

  const addItem = (item, is_deduction ) => {
    props.setReportUpdateHandlerAt(new Date())
    if(is_deduction){
      deduction_list.push(item)
      setDeductionList(deduction_list)
      seetDeductionListUpdatedAt(new Date())  
    }else{
      repot_list.push(item)
      setReportList(repot_list)
      setRepoListUpdatedAt(new Date())  
    }
  }

  const deleteItem = (index, is_deduction ) => {
    if(is_deduction){
      const list = deduction_list
      list.splice(index,1)
      setDeductionList(list)
      seetDeductionListUpdatedAt(new Date())  
    }else{
      const list = repot_list
      list.splice(index,1)
      setReportList(repot_list)
      setRepoListUpdatedAt(new Date())  
    }
    props.setReportUpdateHandlerAt(new Date())
  }
  
  const changeHandler = (item, index, is_deduction) => {
 
    if(is_deduction){
      deduction_list[index] = item
      setDeductionList(deduction_list)
      seetDeductionListUpdatedAt(new Date())  
    }else{
     
      repot_list[index] = item
      setReportList(repot_list)
      setRepoListUpdatedAt(new Date())  
    }
    props.setReportUpdateHandlerAt(new Date())
  } 


  const changeFixedHandler = (item, index) => {
    fixed_list[index] = item
    setFixedList(fixed_list)
    setRepoListUpdatedAt(new Date())  
    props.setReportUpdateHandlerAt(new Date())
  } 
  

  const updateTotal = (_total, is_deduction) => {
    if(!is_deduction){
      setTotal1(_total)
    }else{
      setTotal2(_total)
    }
  }
    
  const updateReports = (reports, _params) => {    
    //reportの最終更新日を取得
    const _last_updated_at = getLatestReportUpdatedAt(reports)
    setLastUpdatedAt(_last_updated_at)
    setParmas(_params)
    createTableContent(reports)
    
  }
  const createTableContent = (reports) => {
  
      if(reports){
        const _l1 = reports.map(item => {
          //itemは
          return item.reportList()
        }).flat(Infinity).filter(item => item !== null)
       
        setReportList(_l1)
        setRepoListUpdatedAt(new Date())
      }
    }

    const getLatestReportUpdatedAt = (reports) => {
      if(reports.length === 0 ) return null

      const _tmp_reports = reports 
      _tmp_reports.sort(function(a, b) {
        if (isAfter( new Date(b.updated_at), new Date(a.updated_at))) {
          return 1;
        } else {
          return -1;
        }
      })
      return _tmp_reports[0].updated_at
    }

    const btnClickHandler = () => {
      props.setReportUpdateHandlerAt(null)
      props.updateHandler(repot_list, deduction_list, fixed_list, params,0)
    }

    const ConfirmHandler = () => {
      props.confirmHandler(props.monthly_payment.id, 1,null,return_comment)
    }
    const ReConfirmHandler = () => {
    props.confirmHandler(props.monthly_payment.id,null,1,return_comment)
    }

    const btnClickConfirmHandler = () => {
      props.setReportUpdateHandlerAt(null)
      props.updateHandler(repot_list, deduction_list, fixed_list, params,1)
    }
  

  const getUniquId = () => {
    const add_str = props.driver ? "_" + props.driver.id : ""
    return props.year + "_" + (props.month) + "_" + props.partner.id + add_str
  }

  const reload = () => {
    setReportList([])
    setRepoListUpdatedAt(new Date())  
    props.reload()
  }
  
  return(
    <div className={props.btn ? "inner" : "inner print"}>
        
        {props.btn ? 
        <div className="mreport_list">
          <div className="title">この支払いに含まれる月報</div>
          {monthly_report_list}
        </div>   
        :null}
        <form >
			  <div className="content_wrap sub_narrow">
					    <div className="page_title_wrap">
                {props.btn ?<h2 className="page_title">支払明細</h2>: null}
               
                {props.btn && props.histories.length > 0 && props.monthly_payment && last_updated_at && isAfter((new Date(last_updated_at)), (new Date(props.monthly_payment.created_at))) ? <div className="reload " onClick={reload}><div className="message">前回保存より後に月報が更新されています({format(new Date(last_updated_at),"yyyy年MM月dd日 HH時mm分に月報更新")})</div><div className="btn-box"><i className="fa-solid fa-rotate-right"></i> 月報から再計算して更新 </div></div> : null}
                {props.btn ? <Breadcrumbs pages={[new LinkProps(props.project ?  props.project.name + " 支払明細・編集 "  : "支払明細・編集" ,"#")]} />: null}
							</div>
							<div className="report_body ">
              
                {header}
               
                <BodyDefault year={props.year}  partner={props.partner}  params={params} month={props.month} 
                  editable = {editable}
                  project={props.project} 
                  start_date={props.start_date}
                  end_date={props.end_date}  
                  repot_list={repot_list} 
                  repo_list_updated_at={repo_list_updated_at} 
                  fixed_list = {fixed_list}
                  changeHandler={changeHandler} 
                  updateTotal={updateTotal}
                  changeParamHandler={changeParamHandler} 
                  changeFixedHandler={changeFixedHandler}
                  addItem = {addItem}
                  deleteItem = {deleteItem}
                  driver = {props.driver}
                  updateReports = {updateReports}
                  monthly_payment = {props.monthly_payment}
                />
                
                <BodyDeduction 
                  editable = {editable}
                  year={props.year} 
                  month={props.month} 
                  project={props.project} 
                  start_date={props.start_date} 
                  end_date={props.end_date}  
                  repot_list={deduction_list} 
                  repo_list_updated_at={deduction_list_updated_at} 
                  params={params}
                  updateTotal={updateTotal}
                  changeHandler={changeHandler}
                  changeParamHandler={changeParamHandler} 
                  addItem = {addItem}
                  deleteItem = {deleteItem}
                  updated_at = {props.updated_at}
                  monthly_payment = {props.monthly_payment}
                  />

                <div>※送付後一定期間内に誤りのある旨の連絡がない場合には、記載内容のとおり確認があったものとさせて頂きます。</div>
              </div>  
           
              {
                editable ? 
                  <div className="makereport">
                  <button className="red" type="button" onClick={btnClickHandler}>一時保存</button>
                  <button className="red" type="button" onClick={btnClickConfirmHandler}>この内容で支払明細を確定</button>
                  <p className="attention">※この画面で編集した内容は日報には反映されません。</p>
                </div>
                : null
              }
          {
                editable ? 
          <div className="histories">
              <h2>保存履歴</h2>
              {histories}
              {histories && histories.length === 1 ? <div className="link" onClick={props.getHitoriesHanlder}>▼全ての履歴を表示</div> : null}
          </div>
            : props.monthly_payment && props.monthly_payment.confirmed_by_partner === null && props.profile && props.profile.user_type === config.get("USER_PARTNER") ? 
            <div className="makereport">
            
              <div className="mb10"><input className="w75" placeholder='差し戻しコメント' type="text" onChange={(e)=> setReturnComment(e.target.value)} value={return_comment}/></div>
              <button className="red" type="button" onClick={ReConfirmHandler}>差し戻す</button> &nbsp;&nbsp;&nbsp;
              <button className="red" type="button" onClick={ConfirmHandler}>この内容を承認する</button>

             </div> : null
          }
           {
                editable  || (props.profile &&  props.profile.user_type === config.get("USER_PARTNER")) ? 
            <div className="return-comment">
            <h2>コメント履歴</h2>
            {comments}
           
           </div>
            : null
          }

        {
          uniqueid  && props.btn ? <MemoComponent category={2} uniqueid={uniqueid} token={props.token} /> : null
        }

				</div>
       </form>
		</div>
    
  )
}

export default MonthlyPaymentDetail

