import { Record } from 'immutable'
import BankInfo from '../bankinfo/BankInfo';


const  PartnerProps = Record({
  id:null,
  name:null,
  kana:null,
  contract_url:null,
  name_in_charge:null,
  kana_in_charge:null,
  invoice_id:null,
  commision:null,
  closing_date:null,
  payment_day_type:null,
  prefecture:null,
  address:null,
  tel:null,
  email:null,
  password:null,
  bank_info:new BankInfo(),
  is_self:0,
  note:null,
  tax_include:1,
  payment_day_day:null,
  payment_day_month:null
,});
export default PartnerProps;

