import React, { useEffect, useState } from 'react'
import useReportState from './useReportState'
import { format, addDays, isSameDay,differenceInCalendarDays, isBefore ,isAfter} from 'date-fns'
import { DummyReportPopup } from './DummyReportPopup'
import { DummyReportDeletePopup } from './DummyReportDeletePopup'

function ReportMyListItem(props:PropsValue) {
 
  //const [is_loading, setIsLoading] = useState(true)
  const [t_list , setTList] = useState(null)
  const [detail, setDetail] = useState(null)

  const {report_list, dummy_list, report_created_at, getReportList,createDummyReport,deleteDummyReport } = useReportState()

  useEffect(() => {

    getReportList(props.token, 1, 1,  props.item.project_id, props.item.driver.id,format(props.end_date,"yyyy-MM-dd"), format(props.start_date,"yyyy-MM-dd"), null, null, true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_created_at])

  

  


  useEffect(() => {
    if(report_list){
      const dif_days = differenceInCalendarDays(props.start_date,props.end_date)
      const _list = (new Array(dif_days)).fill(0).map((dummy, index) => {
        return getResult(addDays(props.end_date,index+1))
      })

      const _list_html = _list.map((r, index) => {
        return <td>{getTag(r, addDays(props.end_date,index+1))}</td>
      })
      const _c = _list.filter(r => r === 3).length
      props.setCounter(props.item.id, _c)
  
      setTList(_list_html.reverse())
      //setIsLoading(false)
     
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_list, props.updated_at])



  const getResult = (date) => {

    const report = report_list.find(r => isSameDay(new Date(r.worked_date), date))
    if(report){
      return 1
    }


    if(isBefore(date, new Date(props.item.start_date))) return 2
    if(props.item.end_date && isAfter(date, new Date(props.item.end_date))) return 2

    var is_target = null
      switch(date.getDay()){
        case 0: 
          is_target = props.item.sunday !== null &&  props.item.sunday !== 0
        break
        case 1: 
          is_target = props.item.monday !== null &&  props.item.monday !== 0
        break
        case 2:  
          is_target = props.item.thuesday !== null &&  props.item.thuesday !== 0
        break
        case 3: 
          is_target = props.item.wednesday !== null &&  props.item.wednesday !== 0
        break
        case 4: 
          is_target = props.item.thursday !== null &&  props.item.thursday !== 0
        break
        case 5: 
          is_target = props.item.friday !== null &&  props.item.friday !== 0
        break
        case 6: 
          is_target = props.item.saturday !== null &&  props.item.saturday !== 0
        break
        default: 
        is_target = false
      }
      
    if(!is_target){
      return 2
    }
    return 3
  }
  const getReportLink = (d) => {

    return "/project/" + props.item.project_uid + "/report/mob/" + props.item.uid + "/new/" + format(d,"yyyy/MM/dd")
  }

  const getDymmyReport = (d) => {
    
    if(!dummy_list) return false

    return dummy_list.find( item => item.target_date === format(d, "yyyy-MM-dd"))
  }
  const getTag = (result,d) => {
    
    switch(result){
      case 1: 
        return (<span className="hasreport">日報あり</span>)
      case 2: 
       
        return (<span>-</span>)
      case 3:
        const dummy = getDymmyReport(d) 
        if(dummy){
          return (<span className="noreport link" onClick={() => displayDelete(dummy.id)}>対象外</span>)
        }else{
          return (<span className="noreport link text-red" onClick={() => displayDetail(d)}>日報なし</span>)
        }
       
      default: 
        return null
    }  
  }

  const createReport = (date) => {
    window.location.href = getReportLink(date)
  }

  const deleteDummy = (id) => {
    deleteDummyReport(props.token, id)
    setDetail(null)
  }
  
  const createDummy = (date) => {
    createDummyReport(props.token, props.item.project_id, props.item.driver_id, format(date, "yyyy-MM-dd"))
    setDetail(null)
  }
  const closeHandler = () => {
    setDetail(null)
  }
  
  const displayDetail = (date) => {
    setDetail(<DummyReportPopup  closeHandler={closeHandler} createReport={createReport} createDummy={createDummy} date={date}/>)
  }

  const displayDelete = (id) => {
    setDetail(<DummyReportDeletePopup closeHandler={closeHandler} deleteDummy={deleteDummy} id={id}/>)
  }
  


  return(
    <>
    {detail ?  <div id="overlay" className="overlay" onClick={closeHandler}></div> : null}
    <tr>
     
      <td className="sticky driver-header">
        <div>{props.project_name} </div><div>{props.item.driver.name}({props.item.counter})</div> 
        <div className="tooltip-user">{props.item.manager_name}</div>
      </td>
      {t_list}

    </tr>
    {detail}
    </>
  )
}

export default ReportMyListItem

