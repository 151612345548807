
import Project from "../project/Project"
import ProjectDriver from "../project/driver/ProjectDriver";
import { differenceInMinutes, setHours, setMinutes } from 'date-fns';


class Report {
  work_start_at ?= "業務開始時間<br/>(運賃発生時間)"
  work_end_at ?= "完了時間 (業務を完了した時間)"
  drive_start_at ?= "発車時間"
  area ?= "配達エリア"
  load_location ?= "積み地"
  delivery_location ?= "納品先"
  detail ?= "業務内容"
  distance ?= "距離（km）"
  meter_start ?= "開始メーター"
  meter_end ?= "終了メーター"
  requested_unit ?= "持出件数"
  delivery_unit ?= "配達件数"
  compleated_unit ?= "宅配完了個数"
  
  nekopos_unit?:string = "ネコポス配完個数"
  dm_unit?:string = "DM配完便個数"
  normal_unit?:string = "一般配完個数"
  
  nekopos_load_unit?:string = undefined
  dm_load_unit?:string = undefined
  normal_load_unit?:string =undefined
  code?:string = undefined

  night_unit ?= "夜間便" 
  load_unit ?= "集荷"
  large_unit ?= "大型"
  return_unit ?= "持戻り"
  return_requested_unit ?= "持戻件数"
  waiting_hour ?= "待機時間"
  fee ?= "当日単価"

  received_gender?:string = undefined
  received_name?:string = undefined

  driver_name?:string = undefined
  spot_type?:Number = undefined
  cost:Boolean = true
  project:Project | null  = null

  project_driver:ProjectDriver | null = null

  status?:Number = 0



  constructor(_project:Project | null = null) {
    this.project = _project
  }

  

  validate(form:any) {
    var errors = []

    if(this.spot_type ===0 && (form.spot_type === null || form.spot_type === 0)){
      errors.push("種類")
    }

    if(this.fee && form.fee === null){
      errors.push(this.fee)
    }

    if(this.work_start_at && form.work_start_at === null){
      errors.push(this.work_start_at)
    }
    if(this.work_end_at && form.work_end_at === null){
      errors.push(this.work_end_at)
    }
    if(this.area && form.area === null){
      errors.push(this.area)
    }
    if(this.load_location && form.load_location === null){
      errors.push(this.load_location)
    }
    if(this.delivery_location && form.delivery_location === null){
      errors.push(this.delivery_location)
    }
    if(this.detail &&( form.detail === null || form.detail === "")){
      errors.push(this.detail)
    }
    if(this.distance && form.distance === null){
      errors.push(this.distance)
    }
    
    if(this.meter_start && form.meter_start === null){
      errors.push(this.meter_start)
    }
    if(this.meter_end && form.meter_end === null){
      errors.push(this.meter_end)
    }
    if(this.delivery_unit && form.delivery_unit === null){
      errors.push(this.delivery_unit)
    }
    if(this.compleated_unit && form.compleated_unit === null){
      errors.push(this.compleated_unit)
    }
    if(this.nekopos_unit && form.nekopos_unit === null){
      errors.push(this.nekopos_unit)
    }
    if(this.nekopos_load_unit && form.nekopos_load_unit === null){
      errors.push(this.nekopos_load_unit)
    }
    if(this.dm_unit && form.dm_unit === null){
      errors.push(this.dm_unit)
    }
    if(this.dm_load_unit && form.dm_load_unit === null){
      errors.push(this.dm_load_unit)
    }
    if(this.normal_unit && form.normal_unit === null){  
      errors.push(this.normal_unit)
    }
    if(this.normal_load_unit && form.normal_load_unit === null){  
      errors.push(this.normal_load_unit)
    }
    if(this.night_unit && form.night_unit === null){
      errors.push(this.night_unit)
    }
    if(this.load_unit && form.load_unit === null){
      errors.push(this.load_unit)
    }
    if(this.large_unit && form.large_unit === null){
      errors.push(this.large_unit)
    }
    if(this.return_unit && form.return_unit === null){
      errors.push(this.return_unit)
    }
    if(this.drive_start_at && form.drive_start_at === null){
      errors.push(this.drive_start_at)
    }
    if(this.requested_unit && form.requested_unit === null){
      errors.push(this.requested_unit)
    }
    if(this.return_requested_unit && form.return_requested_unit === null){
      errors.push(this.return_requested_unit)
    }
    if(this.received_gender && form.received_gender === null){
      errors.push(this.received_gender)
    }
    if(this.received_name && form.received_name === null){
      errors.push(this.received_name)
    }
    if(this.waiting_hour && form.waiting_hour === null){
      errors.push(this.waiting_hour)
    }
   
    
    if(this.driver_name && (form.driver_name === null || form.driver_name === "")){
      errors.push(this.driver_name)
    }

    return errors
  }

  isInMonth(date:Date, year:Number, month:Number){
    
    if(!this.project) return false 

    const p = new Project(this.project)
    const target_date = p.getReportMonthAndYear(date)

    if(!target_date) return false 
   
    if(Number(target_date[0]) === Number(year )&& Number(target_date[1]) === Number(month)) return true

    return false
  }

  getOverTimeHourAddingSelector(over_time_min:number|null) {
    if(!this.project) return null
    switch(Number(this.project.overtime_fee_type)) {
      case 1:
        return null
      case 2:
        return [
          <option value={5} selected={over_time_min === 5 ? true : false}>5分</option>,
          <option value={10} selected={over_time_min === 10 ? true : false}>10分</option>,
          <option value={15} selected={over_time_min === 15 ? true : false}>15分</option>,
          <option value={20} selected={over_time_min === 20 ? true : false}>20分</option>,
          <option value={25} selected={over_time_min === 25 ? true : false}>25分</option>,
          <option value={30} selected={over_time_min === 30 ? true : false}>30分</option>,
          <option value={35} selected={over_time_min === 35 ? true : false}>35分</option>,
          <option value={40} selected={over_time_min === 40 ? true : false}>40分</option>,
          <option value={45} selected={over_time_min === 45 ? true : false}>45分</option>,
          <option value={50} selected={over_time_min === 50 ? true : false}>50分</option>,
          <option value={55} selected={over_time_min === 55 ? true : false}>55分</option>,
          <option value={60} selected={over_time_min === 60 ? true : false}>60分</option>,
        ]
      case 3:
         return [
          <option value={10} selected={over_time_min === 10 ? true : false}>10分</option>,
          <option value={20} selected={over_time_min === 20 ? true : false}>20分</option>,
          <option value={30} selected={over_time_min === 30 ? true : false}>30分</option>,
          <option value={40} selected={over_time_min === 40 ? true : false}>40分</option>,
          <option value={50} selected={over_time_min === 50 ? true : false}>50分</option>,
          <option value={60} selected={over_time_min === 60 ? true : false}>60分</option>,
        ]
      case 4:
      case 5:
         return [
          <option value={15} selected={over_time_min === 15 ? true : false}>15分</option>,
          <option value={30} selected={over_time_min === 30 ? true : false}>30分</option>,
          <option value={45} selected={over_time_min === 45 ? true : false}>45分</option>,
          <option value={60} selected={over_time_min === 60 ? true : false}>60分</option>,
          <option value={75} selected={over_time_min === 75 ? true : false}>75分</option>,
          <option value={90} selected={over_time_min === 90 ? true : false}>90分</option>,
          <option value={105} selected={over_time_min === 105 ? true : false}>105分</option>,
        ]
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
          return [
           <option value={30} selected={over_time_min === 30 ? true : false}>30分</option>,
           <option value={60} selected={over_time_min === 60 ? true : false}>60分</option>,
           <option value={90} selected={over_time_min === 90 ? true : false}>90分</option>,
           <option value={120} selected={over_time_min === 120 ? true : false}>120分</option>,
           <option value={150} selected={over_time_min === 150 ? true : false}>150分</option>,
           <option value={180} selected={over_time_min === 180 ? true : false}>180分</option>,
         ]
      default:
        return [
          <option value={60} selected={over_time_min === 60 ? true : false}>1時間</option>,
          <option value={120} selected={over_time_min === 120 ? true : false}>2時間</option>,
          <option value={180} selected={over_time_min === 180 ? true : false}>3時間</option>
        ]
    }
  }


  getOverTimeHourString(min:Number) {
    if(!this.project || !min) return "-"
    return min + "分"
    /** 
    switch(Number(this.project.overtime_fee_type)) {
      case 1:
      case 2:
      case 3:
        return min + "分"
      default:
          return( Number(min) / 60 )+ "時間"
    }**/
  }




  getOverTimeHour(work_start_at:Date, work_end_at:Date, addition:Number){
    if(!this.project) return 0
    const additional_min = addition ? Number(addition)  : 0
   // const dif_min:number = differenceInMinutes(work_end_at, work_start_at) 
    const working_time_type =  Number(this.project.working_time_type) 
    var over_time_min = 0 //超過時間

    switch(working_time_type) {
      
      case 1: //時間設定
        if(this.project.overtime_start_at && this.project.overtime_close_at){
          const end_time = setMinutes(setHours(work_start_at, Number(String(this.project.overtime_close_at).split(":")[0])), Number(String(this.project.overtime_close_at).split(":")[1]))
          over_time_min = differenceInMinutes(work_end_at,end_time) 
        }
         
        break;
      case 2: //拘束時間
        const dif = differenceInMinutes( work_end_at, work_start_at)
        over_time_min = dif - (Number(this.project.time_set))
        //const before_min = differenceInMinutes(this.project.overtime_start_at, work_start_at) 
        break;
      default : {}
    }

    over_time_min = Number(over_time_min) + Number(additional_min)
    
    
    if(over_time_min <= 0 || this.project.overtime_unit === null || this.project.overtime_start === null) return 0

    var tmp_additional_time = this.project.overtime_unit - this.project.overtime_start
    var target_min = this.project.overtime_unit


    /**
    switch(Number(this.project.overtime_fee_type)) {


      case 1:
        tmp_additional_time = 0
        target_min = 1
        break
      case 2:
        tmp_additional_time = 0
        target_min = 5
        break
        //return ( Math.floor(over_time_min / 5) ) * 5
      case 3: //"10分単位「10分以上で10分単位計算」
        tmp_additional_time = 0
        target_min = 10
        break
        //return ( Math.floor(over_time_min / 10) ) * 10
      case 4: //15分単位②「10分以上で15分単位計算」
        tmp_additional_time = 15 - 10
        target_min = 15
        break

        //if(over_time_min < 10) return 0 
        //return ( Math.ceil(over_time_min / 15) ) * 15

      case 5: //15分単位③「15分以上で15分単位計算」
        tmp_additional_time = 0
        target_min = 15
        break

        //return ( Math.ceil(over_time_min / 15) ) * 15
      case 6: //30分単位①「1分以上で30分単位計算」
        tmp_additional_time = 30 - 1
        target_min = 30
        break

      case 7: //30分単位②「16分以上で30分単位計算
        tmp_additional_time = 30 - 16
        target_min = 30
        break
       
      case 8: //30分単位③「20分以上で30分単位計算」
        tmp_additional_time = 30 - 20
        target_min = 30
        break

      case 9: //30分単位④「21分以上で30分単位計算
        tmp_additional_time = 30 - 21
        target_min = 30
        break

      case 10: //30分単位⑤「30分以上で30分単位計算
        tmp_additional_time = 0
        target_min = 30
        break
      case 11: //60分単位「60分以上で60分単位計算」
        tmp_additional_time = 0
        target_min = 60
        break
      case 12: //15分単位①「5分以上で15分単位計算
        tmp_additional_time = 15 - 5
        target_min = 15
        break
      default:         
    }
     */
    return ( Math.floor((over_time_min   + tmp_additional_time )/ target_min ) ) * target_min
  } 

  getOverDistance(distance:number, addition:number ){


    if(!this.project) return 0
    if(this.project.overdistance_start === null) return 0
    // const dif_min:number = differenceInMinutes(work_end_at, work_start_at) 
    var over:number = distance - (this.project.overdistance_start - 1 )
    var additional_time = addition ? addition : 0
    /**
    switch(Number(this.project.distance_type)) {
      case 1:
        over = 0  ;
        break
      case 3:
        over = distance - 30 ;
        break
      case 4:
        over = distance - 40 ;
        break
      case 5:
        over = distance - 50 ;
        break
      case 6:
        over = distance - 60 ;
        break
      case 7:
        over = distance - 70 ;
        break
      case 8:
        over = distance - 80 ;
        break
      case 9:
        over = distance - 90 ;
        break
      case 10:
        over = distance - 100 ;
        break
      case 11:
        over = distance - 110 ;
        break
      case 12:
        over = distance - 120 ;
        break
      case 13:
        over = distance - 130 ;
        break
      case 14:
        over = distance - 140 ;
        break
      case 15:
        over = distance - 150 ;
        break
      case 16:
          over = distance - 104 ;
          break
      default:
        return 0
    } */

    return over > 0 ? Number(over + additional_time) : additional_time
  }
  
  getTotalUnitNumber(reports:Array<Report>){


    const unit_array:Array<number> = reports.map(report => report.delivery_unit ? Number(report.delivery_unit) : 0)
    return unit_array.reduce((sum, element) => sum + element, 0);
  }



}

export default Report

