import React, {} from 'react';
import icon_dayreport from "../../../images/icon_dayreport.png"
import icon_reports from "../../../images/icon_reports.png"
import icon_case from "../../../images/icon_case.png"
import icon_client from "../../../images/icon_client.png"
import icon_subcontractor from "../../../images/icon_subcontractor.png"
import icon_driver from "../../../images/icon_driver.png"
import icon_user from "../../../images/icon_user.png"
import './sidemenu.css';


interface SideMenuProps {
  /**
   * current page
   */
    current_page?: string,
  
   /**
   * Optional changeHandler handler
   */
    
    user_type?:string
   
}

/**
 * Primary UI component for user interaction
 */
export const SideMenu = ({
  current_page,
  user_type,
  ...props
}: SideMenuProps) => {

  const FINANCE = "finance"
  const PARTNER = "partner"




  return (
    <div id="side" className="side hidden">
            <ul>
            <li >
                    <div className={current_page === "report" ? "side_click selected" : "side_click"}>
                        <img src={icon_dayreport} alt="日報管理" />
                        <div className="hidden_text">
                            <p className="">日報</p>
                        </div>
                    </div>
                    <ul  className="hide side_click_inner">
                        <li>
                            <a href="/report/mine">日報確認チェック</a>
                        </li>
                       
                          <li>
                           <a href="/report">業務日報確認</a>
                          </li>
                        

                    </ul>
                </li>
               
                <li >
                    <div className={current_page === "monthly_report" ? "side_click selected" : "side_click"}>
                        <img src={icon_reports} alt="帳票管理" />
                        <div className="hidden_text">
                            <p className="">帳票</p>
                        </div>
                    </div>
                    <ul  className="hide side_click_inner">
                        <li>
                            <a href="/monthly_report">月報</a>
                        </li>
                       
                        <li>
                            <a href="/monthly_payment">支払明細書</a>
                        </li>
                      
                    </ul>
                </li> 
               
                <li className={user_type === PARTNER ? "disable" :  null}>
                    <div className={current_page === "project" ? "side_click selected" : "side_click"}>
                        <img src={icon_case} alt="案件管理" />
                        <div className="hidden_text">
                            <p className="">案件</p>
                        </div>
                    </div>
                    <ul  className="hide side_click_inner">
                        <li>
                            <a href="/project/new?new=1">案件登録</a>
                        </li>
                        <li>
                            <a href="/project">案件一覧</a>
                        </li>
                    </ul>
                </li>
               
               
                <li className={user_type === PARTNER ? "disable" :  null}>
                    <div className={current_page === "client" ? "side_click selected" : "side_click"}>
                        <img src={icon_client} alt="クライアント管理" />
                        <div className="hidden_text">
                            <p className="">ｸﾗｲｱﾝﾄ</p>
                        </div>
                    </div>
                    <ul  className="hide side_click_inner">
                  
                          <li>
                            <a href="/client/new?new=1">クライアント登録</a>
                          </li>
              
                        <li>
                            <a href="/client">クライアント一覧</a>
                        </li>
                    </ul>
                </li>
                
                
                <li className={user_type === PARTNER ? "disable" :  null}>
                    <div className={current_page === "partner" ? "side_click selected" : "side_click"}>
                        <img src={icon_subcontractor} alt="依頼先管理" />
                        <div className="hidden_text">
                            <p className="">依頼先</p>
                        </div>
                    </div>
                    <ul  className="hide side_click_inner">
                      
                          <li>
                            <a href="/partner/new?new=1">依頼先登録</a>
                          </li>
                      
                        <li>
                            <a href="/partner">依頼先一覧</a>
                        </li>
                    </ul>
                </li>
                 
                 
                <li className={user_type === PARTNER ? "disable" :  null}>
                    <div className={current_page === "driver" ? "side_click selected" : "side_click"}>
                        <img src={icon_driver} alt="ドライバー管理" />
                        <div className="hidden_text">
                            <p className="">ﾄﾞﾗｲﾊﾞｰ</p>
                        </div>
                    </div>
                    <ul  className="hide side_click_inner">
                      
                          <li>
                            <a href="/driver/new">ドライバー登録</a>
                          </li>
                        
                       
                        <li>
                            <a href="/driver">ドライバー一覧</a>
                        </li>
                    </ul>
                </li>
                 
       
                {
                          user_type === FINANCE ? 
                <li className={user_type === PARTNER ? "disable" :  null}>
                    <div className={current_page === "user" ? "side_click selected" : "side_click"}>
                        <img src={icon_user} alt="ユーザー管理" />
                        <div className="hidden_text">
                            <p className="">ユーザー</p>
                        </div>
                    </div>
                    <ul  className="hide side_click_inner">
                     
                          <li>
                            <a href="/user/new?new=1">ユーザー登録</a>
                          </li>
                       
                       
                        <li>
                            <a href="/user">ユーザー一覧</a>
                        </li>
                    </ul>
                </li>
                   : null
                  }
                {
                          user_type === FINANCE ? 
                <li className={user_type === PARTNER ? "disable" :  null}>
                    <div className={current_page === "setting" ? "side_click selected" : "side_click"}>
                        <img src={icon_user} alt="設定" />
                        <div className="hidden_text">
                            <p className="">設定</p>
                        </div>
                    </div>
                    <ul  className="hide side_click_inner">
                        {
                          user_type === FINANCE ? 
                          <li>
                            <a href="/company">自社情報</a>
                          </li>
                          : null
                        }

                       
                
                    </ul>
                </li>
                : null
              }
               
            </ul>
        </div>
  );
};

