import React, { useEffect, useState,  } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../utiles/Utile';

function ClientConfirm(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [title, setTitle] = useState(null)
  const [error_message, setErrorMessage] = useState(null)


  useEffect(() => {
    if(props.client){
      setItem(props.client)
      setTitle(props.client.id ? "クライアント情報"  + config.get("EDIT") : "新規クライアント"  + config.get("REGIST"))
    }
    
  },[props.client])

  useEffect(() => {
    if(props.error)
      setErrorMessage("エラーが発生しました。")
  },[props.error])


  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler(item)
  }

  const backHandler = () => {
   const url = item.id ? "/client/" + item.id : "/client/new"
   window.location.href = url
  }



  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
              <h2 className="page_title">{title}</h2>
                <Breadcrumbs pages={[new LinkProps("クライアント一覧","/client"),new LinkProps(title,"#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">{title} 内容確認</p>
                  {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
									<div className="input_wrap">

                  <table class="input_table col12 login_tbl">
											<tbody>

												<tr>
													<th class="tl">クライアント名</th>
													<td>{item?item.name:null}</td>
												</tr>
												<tr>
													<th class="tl">フリガナ</th>
													<td>{item?item.kana:null}</td>
												</tr>
												<tr>
													<th class="tl">都道府県</th>
													<td>{item && item.prefecture ? Utile.getLabelByValue(config.get("PREFS"),Number(item.prefecture)):null}</td>
												</tr>
												<tr>
													<th class="tl">所在地</th>
													<td>{item?item.address:null}</td>
												</tr>
												<tr>
													<th class="tl">電話番号</th>
													<td>{item?item.tel:null}</td>
												</tr>
												<tr>
													<th class="tl">メールアドレス</th>
													<td>{item?item.email:null}</td>
												</tr>
												<tr>
													<th class="tl">契約書URL</th>
													<td>{item?item.contract_url:null}</td>
												</tr>
												<tr>
													<th class="tl">担当者名</th>
													<td>{item?item.name_in_charge:null}</td>
												</tr>
												<tr>
													<th class="tl">担当者フリガナ</th>
													<td>{item?item.kana_in_charge:null}</td>
												</tr>
												<tr>
													<th class="tl">締め日</th>
													<td>{item && item.closing_date_day ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.closing_date_day))  :null}  </td>
												</tr>
												<tr>
													<th class="tl">請求手数料</th>
													<td>{item && item.fee_rate ? item.fee_rate :null}%</td>
												</tr>
												<tr>
													<th class="tl">支払日</th>
													<td>{item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_MONTH"),Number(item.payment_day_month))  :null} {item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.payment_day_day))  :null}  </td>
												</tr>
												<tr>
													<th class="tl">備考</th>
													<td>{item?item.note:null}</td>
												</tr>
											</tbody>
										</table>

									</div>
								
                </div>
                <div className="button_wrap center">
								
                    <button className="red" type="button" onClick={btnClickHandler}>  { item && item.id ? "この情報で" + config.get("EDIT"):"この情報で"  + config.get("REGIST")} </button>
               
							  </div>

                <div className="button_wrap center">
								  <button className="red" type="button" onClick={backHandler}>入力に戻る</button>
							  </div>


                </div> 
				</div>  
      
		</div>

  )
}

export default ClientConfirm

