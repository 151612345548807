import React, {useState, useEffect  } from 'react'
import { useSearchParams } from "react-router-dom";
import { PageApp } from '../../stories/PageApp'
import Driver from '../drivers/Driver';
import MonthlyPaymentList from "./MonthlyPaymentList"
import { format,isBefore,lastDayOfMonth } from 'date-fns';
import useMonthlyPaymentState from './useMonthlyPaymentState';
import usePartnerState from '../partners/usePartnerState';
import useDriverState from '../drivers/useDriverState';


function MonthlyPaymentListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?  searchParams.get("page") : 1 ;
  const [inhouse, setInhouse] = useState(searchParams.get("inhouse") !== null ?  searchParams.get("inhouse") : "1" );
  const [token, setToken] = useState(null)

  const [total_number, setTotalNumber] = useState(null)
  const {partner_list, getPartnerList} = usePartnerState()
  const [profile, setProfile] = useState(null)
  const today = new Date()
  const [year, setYear] = useState(today.getFullYear())
  const [month, setMonth] = useState( today.getMonth() + 1)
  const {saveSearchParams, loadSearchParams, deleteSearchParams} = useMonthlyPaymentState()
  const {driver_list,  getDriverList} = useDriverState()


  /**  useEffect(() => {
    setTotalNumber(partner_list.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner_updated_at])

   */




  useEffect(() => {

    if(token && profile && profile.id){
      if(profile.user_type === "partner"){
        setInhouse(0)
      }
       const params = loadSearchParams()
      var target_date = new Date(year, month-1, 1)
      if(params){
        setYear(Number(params.year))
        setMonth( Number(params.month))
        target_date = new Date(params.year,params.month-1, 1)
      }
      
      //自社の場合はDriverList 協力会社の場合は協力会社リスト
      if(inhouse && Number(inhouse) === 1 && profile.user_type !== "partner"){ 
        getDriverList(token,1,null,new Driver({is_internal:1}))
      }else{
        
        getPartnerList(token,1,{is_self:1, target_date:format(lastDayOfMonth(target_date), "yyyy-MM-dd")}, 0)
      }
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token , profile])




  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const searchHandler = (e) => {
    const target_date = new Date(e.year, e.month - 1, 1)
    if(isBefore(new Date(), target_date)){
      setTotalNumber(new Date())
      return 
    }
    setTotalNumber(null)
    setYear(Number(e.year))
    setMonth( Number(e.month))
    saveSearchParams({year:e.year, month:e.month, partner_id:e.partner_id})
    if(inhouse && Number(inhouse) === 1){
      getDriverList(token,1,null,new Driver({is_internal:1}))
    }else{
      getPartnerList(token,1,{is_self:1, id:e.partner_id, target_date:format(lastDayOfMonth(target_date), "yyyy-MM-dd")}, 0)
    }


   
    //getReportList(token,page, e)
  }

  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  const deleteSearchParamsHandler = () => {
    deleteSearchParams()
  }

  
  return(
    <div id="home">
      <PageApp current_page="monthly_report" content={<MonthlyPaymentList  profile={profile} inhouse={inhouse}  year={year} month={month} token={token} partners={partner_list} drivers = {driver_list} total_number={total_number} current_page={page} search={searchHandler} deleteSearchParamsHandler={deleteSearchParamsHandler}/>} authChangeHandler={authChangeHandler} profileChangeHandler={profileChangeHandler}/>
    </div>
     
  )
}

export default MonthlyPaymentListApp

