import React, { useEffect, useState } from 'react'
import { format, parseISO,addDays,isSameDay } from 'date-fns';

function BodySTSL(props:PropsValue) {
  
  const [list, setList] = useState(null)
  const [total, setTotal] = useState(0)
  const [other_total, setOtherTotal] = useState(0)
  const [repot_list , setReportList] = useState([])
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())

 
  useEffect(() => {
    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
 
  },[ props.repot_list, props.repo_list_updated_at])

  useEffect(() => {
    
    if(repot_list ){
       


        var highway_total = 0
        var parking_total = 0
        var other_total = 0
  
        var fee_real_total = 0

        const _list = new Array(props.days + 1).fill(0).map((d,index) => {

          const target_day = addDays(props.start_date, index)
          const item = repot_list.find(item => isSameDay(parseISO(item.worked_date),target_day ))

          var highway_cost = 0
          var parking_cost = 0
          var other_cost = 0

          var fee_real = 0 
 
          
          if(item){

     
           // over_time_hour = item.over_time_hour ? item.over_time_hour  : monthly_report.getOverTimeHour(parseISO(item.work_start_at), parseISO(item.work_end_at))
         
            fee_real = item.getRealFee()
      
           
            highway_cost = item.getHighwayCost(item.receipts, item.fee_information ? item.fee_information.highway_fee: 0)
            parking_cost = item.getParkingCost(item.receipts, item.fee_information ?item.fee_information.parking_fee: 0)
            other_cost = item.getOtherCost(item.receipts, item.fee_information ? item.fee_information.other_fee: 0)
            
           
            highway_total += (highway_cost )
            parking_total += parking_cost
            other_total += other_cost 
          
            fee_real_total += Number(fee_real ? fee_real : 0)
            setTotal(fee_real_total)
            setOtherTotal(highway_total + parking_total + other_total)
          }
     
          return(
            <tr className={item ? "link" : null} >
              {props.is_public ||!props.has_edit_right  ? null : <td>{item ? <input type="checkbox" checked={props.selected_list.find( s => (item && s === item.id)) ? true : false} onClick={() => props.checkHandler(item.id)}/> : null}</td>}
              <td onClick={() => item? props.changeHandler(item.id) :null}>{format(target_day, "dd")}</td>
              <td onClick={() => item? props.changeHandler(item.id) :null}>{item && item.project && item.project.client ? item.project.client.name : null}</td>
              
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.detail}</td>) : <td></td> }
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{format(parseISO(item.work_start_at),"H:mm")}</td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{format(parseISO(item.work_end_at),"H:mm")} </td>): <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{fee_real.toLocaleString()} </td>) : <td></td>}
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{highway_cost.toLocaleString()}/{parking_cost.toLocaleString()}/{other_cost.toLocaleString()}</td>): <td></td>}
             
            </tr>
          )
        })
     
      setList(_list)
      
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at,props.selected_at])




  return(
    <div className="body-STSL">
    <table>
      <tbody>
      <tr>
      {props.is_public || !props.has_edit_right? null : <th><input type="checkbox" onChange={props.checkAll}/></th>}
        <th>日</th>
        <th>取引先</th>
        <th>内容</th>
        <th>発時</th>
        <th>完時</th>
        <th>運賃売上</th>
        <th>備考</th>
      </tr>
      {list}
      </tbody>
    </table>
    <div className="total">
      <div>運賃合計 <span>{total.toLocaleString()}</span></div>
      <div>立替金 <span>{other_total.toLocaleString()}</span></div>
    </div>
    <div className="bottom">
      *毎月月末にFAXお願い位します。 株式会社　START LINE 03-6812-0846
    </div>
    </div>
  )
}

export default BodySTSL

