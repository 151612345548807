import React, { useEffect, useState } from 'react'
import useProjectDriverState from '../project/driver/useProjectDriverState';

import useProjectState from '../project/useProjectState';
import useReportState from '../report/useReportState';
import { subMonths,subYears } from 'date-fns';
import Profile from '../../stories/profile/Profile';

function PartnerDetailLinks(props:PropsValue) {
  const [drivers, setDrivers] = useState(null)
  const [profile, setProfile] = useState(null)
  const [reports_list, setReports] = useState(null)
  const [payments, setPayments]= useState(null)
  const {project_driver_list_updated_at, my_project_list, getListByPartnerAndPeriod,isWorkingProject} = useProjectDriverState()
  const {list_updated_at, report_list, getReportListByPartner} = useReportState()
  const {project_list,getProjectList} = useProjectState()
  //const {driver_list, getDriverList} = useDriverState()
  const today = new Date()

  useEffect(() => {
    
    //getDriverList(props.token,1,props.partner.id)
    getListByPartnerAndPeriod(props.token, props.partner.id, today, subYears(today,1) )
    getReportListByPartner(props.token,subYears(today,1),today,props.partner.id)
    setProfile(new Profile(props.profile))
    setDrivers(<a href={"/driver/partner/" +props.partner.id } >全てのドライバーを表示　 <i className="fa-solid fa-arrow-up-right-from-square"></i></a>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.partner])
    /***
  useEffect(() => {
   if(driver_list){
      setDrivers(
        driver_list.map(item => {
          return<div key={"driver" + item.id}><a href={"/driver/" + item.id}>{item.name} <i className="fa-solid fa-arrow-up-right-from-square"></i></a></div>
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver_list]) **/


  //ProjectDriverの読み込み

  useEffect(() => {

    if(project_list && report_list){
      
      const has_monthly_list = []
      const _list = (new Array(12).fill(0).map((m, index) => {
        const target_date = subMonths(today, index)

        //ドライバー一覧

        const _list_manager = project_list.map(project => {
         
          const _list_project_driver = project.drivers 
          return (_list_project_driver.map(project_driver => {
            if(isWorkingProject(project_driver, target_date, report_list)){
              return true
            }else{
              return null
            }
          })).filter(_f => _f !== null)
        }).filter(_f => _f !== null && _f.length !== 0)


        if(_list_manager.length === 0) {
          return null
        }else{
          has_monthly_list.push(target_date.getMonth())
          return <div className="link-list-item"><a  href={"/monthly_report?partner_id=" + props.partner.id + "&year=" + target_date.getFullYear()+ "&month=" + (target_date.getMonth() + 1) }>{target_date.getFullYear()}年{target_date.getMonth() + 1}月</a></div>
        }
      }))


      setReports(_list)

      const _list_payment = (new Array(12).fill(0).map((m, index) => {
        const target_date_p = subMonths(today, index)
        if(has_monthly_list.indexOf(target_date_p.getMonth()) === -1){
          return null //月報がないpaymentは表示させない　（1年単位なので、月だけ見れば良い）
        }else{
          const url = profile.isFinanceManager() ? "/monthly_payment/" + props.partner.id + "/" + target_date_p.getFullYear() + "/" + (target_date_p.getMonth() + 1) + "/e" : "/monthly_payment/" + props.partner.id + "/" + target_date_p.getFullYear() + "/" + (target_date_p.getMonth() + 1) + "/partner" 
          return <div className="link-list-item"><div ><a href={url}>{target_date_p.getFullYear()}年{target_date_p.getMonth() + 1}月</a> </div></div>
        }
       
      }))
      setPayments(_list_payment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_list, list_updated_at])


  useEffect(() => {
    if(my_project_list){
      const driver_ids = my_project_list.map(project_driver => {
        if(project_driver.driver){
          return project_driver.driver.id
        }else{
          return null
        }
        
      })

  

      getProjectList(props.token, 1,driver_ids, {without_brank_driver:true}, 100 )
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_driver_list_updated_at])

  
  
  return(
    <div className="column-right">
      <h4>ドライバー一覧</h4>
      <div className="item-list">
        {drivers}
      </div>
      <h4>月報一覧（過去1年分）</h4>
      <div className="item-list">
        {reports_list}
      </div>
      <h4>支払い明細一覧（過去1年分）</h4>
      <div className="item-list">
        {payments}
      </div>
    </div>
  
    
  )
}

export default PartnerDetailLinks

