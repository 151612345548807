import React, { useEffect, useState,  } from 'react'

import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../utiles/Utile';
import useClientState from '../client/useClientState';
import ReportFormat from '../monthly_report/format/ReportFormat';
import Project from './Project';

function ProjectDelete(props:PropsValue) {
  const report_format = new ReportFormat()
  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const [clientObject, setClientObject] = useState(null)
  const {client, getClient} = useClientState()
  const [format_label, setFormatLabel] = useState(null)

  useEffect(() => {


    if(props.project && props.formats){
      setItem(new Project(props.project))
      getClient(props.token, props.project.client_id)

      const format = report_format.getFormatFromList(props.formats,props.project.report_type )
      if(format){
        setFormatLabel(format.name)
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project, props.formats])


  useEffect(() => {
    setClientObject(client)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client])


  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler()
  }


  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">案件 削除</h2>
                <Breadcrumbs pages={[new LinkProps("案件一覧","/project"),new LinkProps("案件削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">案件削除</p>
                {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
								<div className="input_wrap">
					
                  <table className="input_table col12 login_tbl">
											<tbody>
												
												<tr>
													<th className="tl">クライアント名</th>
													<td>{item && clientObject ?clientObject.name:null}</td>
												</tr>
												<tr>
													<th className="tl">案件名</th>
													<td>{item?item.name:null}</td>
												</tr>
												<tr>
													<th className="tl">区分</th>
													<td>{item && item.project_type ? Utile.getLabelByValue(config.get("PROJECT_TYPE"),Number(item.project_type)):null} </td>
												</tr>
												<tr>
													<th className="tl">種類</th>
													<td>{item && item.category ? Utile.getLabelByValue(config.get("PROJECT_CATEGORY"),Number(item.category)):null}</td>
												</tr>
												<tr>
													<th className="tl">締め日</th>
													<td>{item && item.closing_date_day ? Number(item.closing_date_day):null}日</td>
												</tr>
												<tr>
													<th className="tl">月報ひな形</th>
													<td>{format_label}</td>
												</tr>
												<tr>
													<th className="tl">残業設定</th>
													<td>{item && item.overtime_start ? <span>{item.overtime_start}分以上で{item.overtime_unit}単位</span> :  <span>なし</span>}</td>
												</tr>
												<tr>
													<th className="tl">距離設定</th>
													<td>{item && item.overdistance_start ? <span>{item.overdistance_start}km以上計算</span>: <span>なし</span>}</td>
												</tr>
												<tr>
													<th className="tl">勤務時間設定</th>
													<td>{item && item.working_time_type ? Utile.getLabelByValue(config.get("PROJECT_WORKING_TIME_TYPE"),Number(item.working_time_type)):null}</td>
												</tr>
												<tr>
													<th className="tl">時間設定開始時刻</th>
													<td>{item?item.overtime_start_at:null}</td>
												</tr>
												<tr>
													<th className="tl">時間設定終了時刻</th>
													<td>{item?item.overtime_close_at:null}</td>
												</tr>
												<tr>
													<th className="tl">拘束時間設定</th>
													<td>{item?item.getOverTimeString():null}</td>
												</tr>

												<tr>
													<th className="tl">備考</th>
													<td>{item?item.note:null}</td>
												</tr>
											</tbody>
										</table>
								</div>
							</div>
              <div className="button_wrap center">
								 <button className="red" type="button" onClick={btnClickHandler}>削除する</button>
               </div>
            </div> 
				</div>  
      
		</div>

  )
}

export default ProjectDelete
