import React, { useEffect, useState,  } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../../utiles/Utile';
import CreateProject from '../projectObject/CreateProject';
//project
import useProjectState from '../useProjectState';
//parter
import usePartnerState from '../../partners/usePartnerState';
//driver 
import useDriverState from '../../drivers/useDriverState';
//user
import useUserState from '../../user/useUserState';

function ProjectDriverConfirm(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)

  const [project_object, setProjectObject] = useState(null)
  const {project, getProject} = useProjectState()

  const [partner_object, setPartnerObject] = useState([])
  const {partner, getPartner}= usePartnerState()

  const [driver_object, setDriverObject] = useState([])
  const {driver, getDriver}= useDriverState()

  const {user, getUserDetail}= useUserState()


  useEffect(() => {

    if(props.project_driver){
      setItem(props.project_driver)
      getDriver(props.token, props.project_driver.driver_id)
      getPartner(props.token, props.project_driver.partner_id ? props.project_driver.partner_id : props.project_driver.driver? props.project_driver.driver.partner.id : 0)
      getProject(props.token, props.project_id)

      getUserDetail(props.token, props.project_driver.manager_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project_driver])

  useEffect(() => {
    if(props.error)
      setErrorMessage("エラーが発生しました。")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.error])

  useEffect(() => {
    if(project)
      setProjectObject(CreateProject.create(project))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project])


  useEffect(() => {
    setDriverObject(driver)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver])

  useEffect(() => {
    setPartnerObject(partner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner])


  useEffect(() => {
    setPartnerObject(partner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner])

  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler(item)
  }

  const backHandler = () => {
   const url = item.id ? "/project/" + props.project_id +"/driver/" + item.id  : "/project/" + props.project_id +"/driver/new" 
   window.location.href = url
  }


  const getWorkingDayStying = () => {
    const days = []
    if(!item ) return null 
    if(Number(item.sunday)) days.push("日")
    if(Number(item.monday)) days.push("月")
    if(Number(item.thuesday)) days.push("火")
    if(Number(item.wednesday)) days.push("水")
    if(Number(item.thursday)) days.push("木")
    if(Number(item.friday)) days.push("金")
    if(Number(item.saturday)) days.push("土")
    return days.join(",")
  }

  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
              <h2 className="page_title">担当ドライバー追加</h2>
              <Breadcrumbs pages={[new LinkProps("案件一覧","/project"),new LinkProps("担当ドライバー追加","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">担当ドライバー追加 内容確認</p>
                  {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
									<div className="input_wrap">
                  <table className="input_table col12 login_tbl">
											<tbody>
												<tr>
													<th className="tl">クライアント名</th>
													<td>{project_object ? project_object.client.name : null}</td>
												</tr>
												<tr>
													<th className="tl">案件名</th>
													<td>{project_object ? project_object.name : null}</td>
												</tr>
												<tr>
													<th className="tl">区分</th>
													<td>{project_object && project_object.project_type ? Utile.getLabelByValue(config.get("PROJECT_TYPE"),Number(project_object.project_type)):null} </td>
												</tr>
												<tr>
													<th className="tl">種類</th>
													<td>{project_object && project_object.category ? Utile.getLabelByValue(config.get("PROJECT_CATEGORY"),Number(project_object.category)):null}</td>
												</tr>
												<tr>
													<th className="tl">依頼先会社名</th>
													<td>{partner_object ? partner_object.name : null}</td>
												</tr>
                        <tr>
													<th className="tl">担当マネージャー</th>
													<td>{user ? user.name : null}</td>
												</tr>
	
												<tr>
													<th className="tl">担当ドライバー</th>
													<td>{driver_object ? driver_object.name : null}</td>
												</tr>
												<tr>
													<th className="tl">案件開始日</th>
													<td>{item ? item.start_date : null}</td>
												</tr>

                        <tr>
													<th className="tl">案件終了日</th>
													<td>{item ? item.end_date : null}</td>
												</tr>
												<tr>
													<th className="tl">日報チェック</th>
													<td>{item ? getWorkingDayStying(): null}</td>
												</tr>
                        {project_object &&  project_object.is_unit_price ?
                        <tr>
													<th className="tl">支払い単価</th>
													<td>{item ? Number(item.fee_information.unit_price).toLocaleString() : null}円</td>
												</tr>
                        :null}
                        {project_object &&  Number(project_object.category) === 4 ?
                        <tr>
													<th className="tl">ヤマト宅配通常支払い単価</th>
													<td>{item ? Number(item.fee_information.unit_yamato_normal).toLocaleString() : null}円</td>
												</tr>
                        :null}
                         {project_object &&  Number(project_object.category) === 4 ?
                        <tr>
													<th className="tl">ヤマト宅配ネコポス支払い単価</th>
													<td>{item ? Number(item.fee_information.unit_yamato_nekopos).toLocaleString() : null}円</td>
												</tr>
                        :null}
                        {project_object &&  Number(project_object.category) === 4 ?
                        <tr>
													<th className="tl">ヤマト宅配DM支払い単価</th>
													<td>{item ? Number(item.fee_information.unit_yamato_dm).toLocaleString() : null}円</td>
												</tr>
                        :null}

                        {project_object &&  Number(project_object.category) === 5 ?
                        <tr>
													<th className="tl">通常単価</th>
													<td>{item ? Number(item.fee_information.unit_yusei_normal).toLocaleString() : null}円</td>
												</tr>
                        :null}
                         {project_object &&  Number(project_object.category) === 5 ?
                        <tr>
													<th className="tl">大型単価</th>
													<td>{item ? Number(item.fee_information.unit_yusei_big).toLocaleString() : null}円</td>
												</tr>
                        :null}
                        {project_object &&  Number(project_object.category) === 5 ?
                        <tr>
													<th className="tl">夜間単価</th>
													<td>{item ? Number(item.fee_information.unit_yusei_yakan).toLocaleString() : null}円</td>
												</tr>
                        :null}

                        {project_object &&  Number(project_object.category) === 5 ?
                        <tr>
													<th className="tl">集荷単価</th>
													<td>{item ? Number(item.fee_information.unit_yusei_collect).toLocaleString() : null}円</td>
												</tr>
                        :null}

                        {project_object &&  Number(project_object.category) === 7 ?
                        <tr>
													<th className="tl">歩合超過{project_object && project_object.unit ? project_object.unit : null}数</th>
													<td>{item ? Number(item.fee_information.over_point_number).toLocaleString() : null}{project_object && project_object.unit ? project_object.unit : null}以上</td>
												</tr>
                        :null}

                        {project_object &&  Number(project_object.category) === 7 ?
                        <tr>
													<th className="tl">歩合超過単価</th>
													<td>{item ? Number(item.fee_information.over_point_unit_price).toLocaleString() : null}円/{project_object && project_object.unit ? project_object.unit : null}</td>
												</tr>
                        :null}

                          {project_object ? project_object.is_over_time_fee  : "=-"}
                         {project_object &&  project_object.is_over_time_fee ?
                        <tr>
													<th className="tl">支払い残業単価</th>
													<td>{item && item.fee_information.over_time_unit_price ? Number(item.fee_information.over_time_unit_price).toLocaleString() : 0}円/{project_object ? project_object.getOverTimeUnitTime() : null}分</td>
												</tr>
                        :null}
                        {project_object &&  project_object.is_over_distance_fee ?
                        <tr>
													<th className="tl">支払い超過距離単価</th>
													<td>{item && item.fee_information.over_distance_unit_price ? Number(item.fee_information.over_distance_unit_price).toLocaleString() : 0}円/km</td>
												</tr>
                        :null}
												<tr>
													<th className="tl">条件</th>
													<td>{item && item.condition ? Utile.getLabelByValue(config.get("PROJECT_DRIVER_WORKING_TYPE"),Number(item.condition)):null}</td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td>{item ? item.note : null}</td>
												</tr>
											</tbody>
										</table>

									</div>
								
                </div>
                <div className="button_wrap center">
								
                    <button className="red" type="button" onClick={btnClickHandler}>  { item && item.id ? "この情報で" + config.get("EDIT"):"この情報で"+ config.get("REGIST")} </button>
               
							  </div>

                <div className="button_wrap center">
								  <button className="red" type="button" onClick={backHandler}>入力に戻る</button>
							  </div>


                </div> 
				</div>  
      
		</div>

  )
}

export default ProjectDriverConfirm

