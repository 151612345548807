import React, { useEffect, useState,  } from 'react'

import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../utiles/Utile';
function PartnerConfirm(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const [title, setTitle] = useState(null)

  useEffect(() => {
    setItem(props.partner)
    setTitle(props.partner && props.partner.id ? "依頼先情報編集" : "依頼先登録")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.partner])

  useEffect(() => {
    if(props.error)
      setErrorMessage("エラーが発生しました。すでに登録されているメールアドレスの可能性があります。")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.error])


  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler(item)
  }

  const backHandler = () => {
   const url = item.id ? "/partner/" + item.id : "/partner/new"
   window.location.href = url
  }



  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
            <h2 className="page_title">{title}</h2>
                <Breadcrumbs pages={[new LinkProps("依頼先一覧","/partner"),new LinkProps(title,"#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">{title} 内容確認</p>
                  {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }      
									<div className="input_wrap">
										
										<table className="input_table col12 login_tbl">
											<tbody>

												<tr>
													<th className="tl">依頼先会社名</th>
													<td>{item?item.name:null}</td>
												</tr>
												<tr>
													<th className="tl">フリガナ</th>
													<td>{item?item.kana:null}</td>
												</tr>
												<tr>
													<th className="tl">都道府県</th>
													<td>{item && item.prefecture ? Utile.getLabelByValue(config.get("PREFS"),Number(item.prefecture)):null}</td>
												</tr>
												<tr>
													<th className="tl">所在地</th>
													<td>{item?item.address:null}</td>
												</tr>
												<tr>
													<th className="tl">電話番号</th>
													<td>{item?item.tel:null}</td>
												</tr>
												<tr>
													<th className="tl">メールアドレス</th>
													<td>{item?item.email:null}</td>
												</tr>
                        <tr>
													<th className="tl">パスワード</th>
													<td>******</td>
												</tr>
												<tr>
													<th className="tl">契約書URL</th>
													<td>{item?item.contract_url:null}</td>
												</tr>
												<tr>
													<th className="tl">担当者名</th>
													<td>{item?item.name_in_charge:null}</td>
												</tr>
												<tr>
													<th className="tl">担当者フリガナ</th>
													<td>{item?item.kana_in_charge:null}</td>
												</tr>
												<tr>
													<th className="tl">振込先情報</th>
													<td>{item?item.bank_info.bank_name + " " + item.bank_info.branch_name  + " " + item.bank_info.account_type + " " + item.bank_info.account_id + " " + item.bank_info.account_name  :null}</td>
												</tr>
												<tr>
													<th className="tl">支払い手数料</th>
													<td>{item && item.commision ?item.commision :null} % </td>
												</tr>
												<tr>
													<th class="tl">支払日</th>
													<td>{item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_MONTH"),Number(item.payment_day_month))  :null} {item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.payment_day_day))  :null}  </td>
												</tr>

                        <tr>
													<th className="tl">税区分</th>
													<td>{item && item.tax_include ? Utile.getLabelByValue(config.get("TAX_INCLUDE"),Number(item.tax_include)):null} </td>
												</tr>
												<tr>
													<th className="tl">インボイス番号</th>
													<td>{item?item.invoice_id:null}</td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td>{item?item.note:null}</td>
												</tr>
											</tbody>
										</table>
									</div>
								
                </div>
                {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }      
                <div className="button_wrap center">   
								
                    <button className="red" type="button" onClick={btnClickHandler}>  { item && item.id ? "この情報で"+ config.get("EDIT"):"この情報で"+ config.get("REGIST")} </button>
               
							  </div>

                <div className="button_wrap center">
								  <button className="red" type="button" onClick={backHandler}>入力に戻る</button>
							  </div>


                </div> 
				</div>  
      
		</div>

  )
}

export default PartnerConfirm

