import React, { useEffect, useState } from 'react'
import Utile from '../../../utiles/Utile';
import { format, parseISO,addDays,isSameDay } from 'date-fns';
function BodySpot(props:PropsValue) {
  
  const [list, setList] = useState(null)
  const [repot_list , setReportList] = useState([])
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())

  useEffect(() => {
   
    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ props.repot_list, props.repo_list_updated_at])

  useEffect(() => {
    
    
    if(repot_list ){

        var highway_cost_total = 0
        var parking_cost_total = 0
        var other_cost_total = 0
        var fee_total = 0
        var fee_real_total = 0
      
        const _list = new Array(props.days + 1).fill(0).map((d,index) => {

          const target_day = addDays(props.start_date, index)
          const items = repot_list.filter(item => isSameDay(parseISO(item.worked_date),target_day ))
          if(items.length === 0){
            return null
          }
          return items.map(item => {
            console.log(item)
            fee_total += item.fee 
            const highway_cost = item.getHighwayCost(item.receipts, item.fee_information ? item.fee_information.highway_fee: 0)
            const parking_cost = item.getParkingCost(item.receipts, item.fee_information ?item.fee_information.parking_fee: 0)
            const  other_cost = item.getOtherCost(item.receipts, item.fee_information ? item.fee_information.other_fee: 0)
            const fee_real = item.getRealFee(0, item.fee_information ? item.fee_information.unit_price : 0)
            highway_cost_total += highway_cost 
            parking_cost_total += parking_cost
            other_cost_total += other_cost
            fee_real_total += Number(fee_real ? fee_real : 0)
            return(
              <tr className={item ? "link" : null} >
                 {props.is_public ||!props.has_edit_right  ? null : <td>{item ? <input type="checkbox" checked={props.selected_list.find( s => (item && s === item.id)) ? true : false} onClick={() => props.checkHandler(item.id)}/> : null}</td>}
                <td onClick={() => props.changeHandler(item.id)}>{format(target_day, "d")}</td>
                <td onClick={() => props.changeHandler(item.id)}>{Utile.getWeekString(target_day)}</td>
                {item ? (<td onClick={() => props.changeHandler(item.id)}><input type="text" className="detail" value={item.driver_name} name="detail" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
                {item ? (<td onClick={() => props.changeHandler(item.id)}><input type="text" className="detail" value={item.detail} name="detail" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
                {item ? (<td onClick={() => props.changeHandler(item.id)}><input type="text" className="detail" value={item.load_location} name="load_location" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
                {item ? (<td onClick={() => props.changeHandler(item.id)}><input type="text" className="detail" value={item.delivery_location} name="delivery_location" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
                {item ? (<td onClick={() => props.changeHandler(item.id)}>{format(parseISO(item.work_start_at), "HH:mm")}</td>) : <td></td> }
                {item ? (<td onClick={() => props.changeHandler(item.id)}>{format(parseISO(item.work_end_at), "HH:mm")}</td>) : <td></td> }
                {item ? (  <td onClick={() => props.changeHandler(item.id)}>{item.distance} <span className="km">km</span></td>): <td></td>}
                {item ? (  <td onClick={() => props.changeHandler(item.id)}>{item.waiting_hour / 60 ? item.waiting_hour / 60 : 0} <span className="yen_mark" >h</span></td>) : <td></td>}
                {!props.is_public ? 
                  item ? (<td onClick={() => props.changeHandler(item.id)}>{item.fee ? <span className="yen_mark">&yen;</span>: null}{item.fee ? item.fee.toLocaleString() : null}</td>): <td></td>
                  : null
                }
                {!props.is_public ? 
                  item ? (<td onClick={() => props.changeHandler(item.id)}>{fee_real ? <span className="yen_mark">&yen;</span>: null}{fee_real ? fee_real.toLocaleString() : null}</td>): <td></td>
                  : null
                }
                { item ?  (    <td onClick={() => props.changeHandler(item.id)}>{highway_cost.toLocaleString()}/{parking_cost ? parking_cost.toLocaleString() : 0}/{other_cost ? other_cost.toLocaleString() : 0}</td>): <td></td>}
              </tr>
            )
          })
          
        })
      _list.push(
        <tr>
        <td colspan={props.is_public ||!props.has_edit_right  ?  10 : 11}  className="report_subtotal">合計</td>
        {!props.is_public ? 
        <td><span className="yen_mark">&yen;</span>{fee_total.toLocaleString()}</td>
        : null
        }
        {!props.is_public ? 
        <td><span className="yen_mark">&yen;</span>{fee_real_total.toLocaleString()}</td>
        : null
        }
        <td>{highway_cost_total.toLocaleString()}/{parking_cost_total.toLocaleString()}/{other_cost_total.toLocaleString()}</td>
        </tr>
      )
      setList(_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at,props.selected_at])




  return(
    <div>
    <table className="spot"> 
      <tbody>
      <tr>
      {props.is_public ||!props.has_edit_right  ? null : <th><input type="checkbox" onChange={props.checkAll}/></th>}<th>日</th><th>曜</th><th className="min10">ドライバー名</th><th className="min10">業務内容</th><th className="min10">積み地</th><th>納品先</th><th>発時</th><th>完了</th><th>距離</th><th>待機</th>
        {!props.is_public ? <th>報告運賃</th> : null}
        {!props.is_public ? <th>支払単価</th> : null}
        <th>高速/駐車/その他</th>
      </tr>
      {list}
      </tbody>
    </table>
    <p className="report_manth spot">稼働年月：<input type="text" className="in01" value={props.year} />年<input type="text" className="in01" value={props.month}/>月</p>
   
    </div>
  )
}

export default BodySpot

/**
 * {item ? (<td><input type="text" className="detail" value={item.area} name="detail" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
              {item ? (<td><input type="text" className="detail" value={item.load_location} name="load_location" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
              {item ? (<td><input type="text" className="detail" value={item.delivery_location} name="delivery_location" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
              {item ? (<td><input type="time" className="report_time" value={format(item.work_start_at, "HH:mm")} name="work_start_at" onChange={(e) => props.changeTimeHandler(e, item.id)} /></td>) : <td></td> }
              {item ? (<td><input type="time" className="report_time" value={format(item.work_end_at, "HH:mm")} name="work_end_at" onChange={(e) => props.changeTimeHandler(e, item.id)} /></td>) : <td></td> }
              {item ? (  <td><input type="number"  className="report_distance" value={item.distance}  name="distance" onChange={(e) => props.changeHandler(e, item.id)} /><span className="km">km</span></td>): <td></td>}
              {item ? (  <td><input type="number" className="" value={item.waiting_hour}   name="waiting_hour" onChange={(e) => props.changeHandler(e, item.id)} /><span className="yen_mark" >h</span></td>) : <td></td>}
              {item ? (    <td><span className="yen_mark">&yen;</span><input type="number" name="fee" onChange={(e) => props.changeHandler(e, item.id)}  className="report_reimbursement" value={item.fee}  /></td>): <td></td>}
              {item ? (    <td><span className="yen_mark">&yen;</span><input type="number" name="tatekae" onChange={(e) => props.changeHandler(e, item.id)}  className="report_reimbursement" value={item.tatekae}  /></td>): <td></td>}
 */