import React, { useEffect, useState,  } from 'react'
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import config from 'react-global-configuration';
import Utile from '../../utiles/Utile';


//Partner
import usePartnerState from '../partners/usePartnerState';

function DriverConfirm(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [partner_object, setPartnerObject] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const {partner, getPartner} = usePartnerState()
  const [title, setTitle] = useState(null)
  
  useEffect(() => {
    if(props.driver){
      setItem(props.driver)
      getPartner(props.token, props.driver.partner_id)
      setTitle(props.driver.id ? "ドライバー情報" + config.get("EDIT") : "新規ドライバー" + config.get("REGIST"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.driver])

  useEffect(() => {
    if(props.error)
      setErrorMessage("エラーが発生しました。")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.error])



  useEffect(() => {
    setPartnerObject(partner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner])

  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler(item)
  }

  const backHandler = () => {
   const url = item.id ? "/driver/" + item.id : "/driver/new"
   window.location.href = url
  }



  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
              <h2 className="page_title">{title}</h2>
                <Breadcrumbs pages={[new LinkProps("ドライバー一覧","/driver"),new LinkProps(title,"#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">{title} 内容確認</p>
                  {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
									<div className="input_wrap">
                  <table className="input_table col12 login_tbl">
											<tbody>
											
												
												<tr>
													<th className="tl">ドライバー名</th>
													<td>{item?item.name:null}</td>
												</tr>
												<tr>
													<th className="tl">フリガナ</th>
													<td>{item?item.kana:null}</td>
												</tr>
												<tr>
													<th className="tl">所属企業</th>
													<td>{partner_object?partner_object.name:null}</td>
												</tr>
												<tr>
													<th className="tl">電話番号</th>
													<td>{item?item.tel:null}</td>
												</tr>
												<tr>
													<th className="tl">車両番号</th>
													<td>{item?item.car_no:null}</td>
												</tr>
												<tr>
													<th className="tl">インボイス番号</th>
													<td>{item?item.invoice:null}</td>
												</tr>
                        {item && item.company_driver === 1 ? 
												<tr>
													<th className="tl">支払い手数料</th>
													<td>{item ? item.commision :null} % </td>
												</tr>
                        : null}
                        
                        {item && item.company_driver === 1 ? 
												<tr>
													<th className="tl">支払いサイト</th>
													<td>{item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_MONTH"),Number(item.payment_day_month))  :null} {item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE_DAY"),Number(item.payment_day_day))  :null}  </td>
												</tr>
                        : null}

                        {item && item.company_driver === 1 ? 
												<tr>
													<th className="tl">振込先情報</th>
													<td>{item && item.bank_info.bank_name ? item.bank_info.bank_name + " " + item.bank_info.branch_name  + " " + item.bank_info.account_type + " " + item.bank_info.account_name + " " + item.bank_info.account_id :null}</td>
												</tr>
                        : null}
												<tr>
													<th className="tl">備考</th>
													<td>{item?item.note:null}</td>
												</tr>
                        
											</tbody>
										</table>

									</div>
								
                </div>
                <div className="button_wrap center">
								
                    <button className="red" type="button" onClick={btnClickHandler}>   { item && item.id ? "この情報で" + config.get("EDIT"):"この情報で"+ config.get("REGIST")} </button>
               
							  </div>

                <div className="button_wrap center">
								  <button className="red" type="button" onClick={backHandler}>入力に戻る</button>
							  </div>


                </div> 
				</div>  
      
		</div>

  )
}

export default DriverConfirm

