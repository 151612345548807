import React, {useState,useEffect} from 'react';

import Select from 'react-select';
import usePartnerState from '../../../components/partners/usePartnerState';
import Utile from '../../../utiles/Utile';

interface ParnterSearchBoxProps {

    token:string,
    defaultValue:Number,
  /**
    * changeHandler
    */
   changeHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const ParnterSearchBox = ({
  token,
  no_mycompany,
  defaultValue,
  changeHandler,
  ...props
}: ParnterSearchBoxProps) => { 

  const [partners, setPartners] = useState(null)
  const {partner_list, getPartnerList} = usePartnerState()
  const [value, setValue] = useState(null)

  useEffect(() => {
   
    if(defaultValue){
      getPartnerList(token,1,{id:defaultValue})
    }else{
      getPartnerList(token, 1)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token, defaultValue])

  useEffect(() => {
    
    if(partner_list){
      const _list = [{value:0, label:"選択してください"}]
      setPartners(_list.concat(partner_list.map(item => {
        if(no_mycompany && item.is_self === 1) return null
        const  _name = Number(item.is_self) === 1 ? item.name + "(自社)" : item.name
        return {value:item.id, label:_name, is_self:item.is_self}
      })).filter(f => f !== null))
      if(value === null){
        const def = partner_list.filter(item => item.id ===  Number(defaultValue))
        if(def.length > 0){
          const def_val = {value:Number(def[0].id), label:def[0].name}
          setValue(def_val)
        }
      }
    }
   
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner_list])

  const onInputChangeHandler = (e) => {
    
    getPartnerList(token,1,{name:e})
  }


  const onChangeHandler = (e) => {
  
    changeHandler(e)
    setValue(e)
  }



  return (
    <Select
       placeholder= "選択してください"
       options={partners ? partners : []}
       value = {value}
       onInputChange={onInputChangeHandler}
       noOptionsMessage={()=>""}
       onChange={onChangeHandler}
       styles={Utile.getDefaultSelectStyle(200)}
    />
   
  );
};

