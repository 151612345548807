import { Record } from 'immutable'
import BankInfo from '../bankinfo/BankInfo';


const DriverProps = Record({
  id:null,
  uid:null,
  is_internal:null,
  name:null,
  kana:null,
  partner_id:0,
  start_date:null,
  end_date:null,
  user_id:null,
  invoice:null,
  address:null,
  tel:null,
  car_no:null,
  commision:null,
  payment_day_month:null,
  payment_day_day:null,
  company_driver:null,
  spot_driver:null,
  bank_info:new BankInfo(),
  note:null,
  partner:null,
  user:null
});
export default DriverProps;

