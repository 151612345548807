
import Report from "../Report"

class ReportCommition  extends Report{
  delivery_unit = "持出個数"



  drive_start_at =  undefined
  area=  "エリア"
  load_location =  undefined
  delivery_location =  undefined
  requested_unit = "便数"
  distance =  "距離"
  meter_start=  undefined
  meter_end =  undefined
  compleated_unit =  undefined
  nekopos_unit=  undefined
  dm_unit =  undefined
  normal_unit =  "配完数"
  night_unit =  undefined
  load_unit =  undefined
  large_unit =  undefined
  waiting_hour =  undefined
  return_requested_unit = undefined
  return_unit = undefined
  fee = "当日単価"

  getTotalUnitNumber(reports:Array<Report>){
    const unit_array:Array<number> = reports.map(report => report.delivery_unit ? Number(report.delivery_unit) : 0)
    return unit_array.reduce((sum, element) => sum + element, 0);
  }

}

export default ReportCommition

