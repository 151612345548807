import { addMonths, differenceInMonths, format, isBefore } from 'date-fns'
import React, { useEffect, useState } from 'react'
import Project from './Project'

function ProjectDetailLinks(props:PropsValue) {
  const [project, setProject] = useState(null)
  const [drivers, setDrivers] = useState(null)
  const [drivers_not_working, setNotWorkingDrivers] = useState(null)
  const [reports, setMonthlyReports] = useState(null)
 
  
  useEffect(() => {
    setProject(props.project)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project])

  useEffect(() => {
    
    if(project && project.drivers){
      const _project = new Project(project)
      const _working_drivers = _project.getWorkingDriver().map(
        item => <div key={"driver" + item.id}><a href={"/project/" + project.id + "/driver/" + item.id}>{item.driver ? item.driver.name : null} ({item.driver ? item.driver.partner.name : null}) 稼働条件表示 <i className="fa-solid fa-arrow-right"></i></a></div>
      )
      const _not_working_drivers = _project.getNotWorkingDriver().map(
        item => <div key={"driver" + item.id}><a href={"/project/" + project.id + "/driver/" + item.id}>{item.driver ? item.driver.name : null} ({item.driver ? item.driver.partner.name : null}) 稼働条件表示 <i className="fa-solid fa-arrow-right"></i></a></div>
      )
   
     
      setDrivers(_working_drivers)
      setNotWorkingDrivers(_not_working_drivers)

      var project_driver_ids = []
      setMonthlyReports(
        project.drivers.map(project_driver => {
          project_driver_ids.push(project_driver.id)
          const start_date = new Date(project_driver.start_date)
          var  end_date = project_driver.end_date ? new Date(project_driver.end_date) : new Date()
          if(isBefore(new Date(),end_date)){
            end_date = new Date()
          }

          //TODO 日報があるかどうかチェック
          const dif_month = differenceInMonths(end_date,start_date)
          const _array = new Array(dif_month + 1)
          _array.fill(0)
          return(
          <div key={"report" + project_driver.id}>{project_driver.driver ? project_driver.driver.name  : null } ({project_driver.driver ? project_driver.driver.partner.name: null}) 
            {_array.map((i,index) => {
              const target_date = addMonths(start_date, index)
              return (<div className="sub-list"><a href={"project/" + project_driver.project_uid + "/monthly_report/" + project_driver.uid+ "/" + format(target_date, "yyyy/M")} >{format(target_date, "yyyy年M月月報")}</a></div>)
            })}
          </div>)
        })
      )
  
      //<a href={"/monthly_report?client=" + props.project.client.id}>月報を表示　<i className="fa-solid fa-arrow-up-right-from-square"></i></a>
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project])

  

  return(
    <div className="column-right">
      <h4>担当ドライバー一覧</h4>
      <div className="item-list">
        {drivers}
      </div>

      <h4>担当ドライバー（終了済み）一覧</h4>
      <div className="item-list">
        {drivers_not_working}
      </div>


      <h4>月報</h4>
      <div className="item-list">
       {reports}
      </div>
    </div>
  
    
  )
}

export default ProjectDetailLinks

