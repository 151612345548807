import React, {useState} from 'react';
import { Record } from 'immutable'
import PartnerProps from '../../../components/partners/PartnerProps';
import config from 'react-global-configuration';

interface SearchPartnerProps {
   /**
   * content
   */
   token:String,

   /**
    * Form 
    */
   data:?Record,

   /**
    * SearchBtnClickHandler
    */
   btnClickHandler:Function,

   clearHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const SearchPartner = ({
  //current_page,
  token,
  content,
  data,
  btnClickHandler,
  clearHandler,
  ...props
}: SearchPartnerProps) => { 

  const [form_css, setFormCss] = useState(null)
  const [form_active, setFormActive] = useState("active")
  const [item, setItem]= useState(new PartnerProps({is_self:1}))


  const toggleSearch = () => {
    if(form_css){
      setFormCss(null)
      setFormActive("active")
    }else{
      setFormCss("search-hidden")
      setFormActive(null)
    }
  }

  const onChangHandler = (e) => {
    const target = e.target 
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
  }

  const clickHandler = () => {
    btnClickHandler(item)
  }


  const kedownHandler = (e) => {
    if (e.key === 'Enter') {
      clickHandler()
    }
  }

  return (
    <div className="container more_search">
      <div className="column">
      <p id="more_search_ttl" className={form_active} onClick={toggleSearch}>絞り込み検索</p>
      <form className={form_css} onKeyDown={kedownHandler}>
        <div>
          <div >
             
            <div>
                <div className="input_wrap">
                  
                  <label className="">
                    <span className="label_name">依頼先会社名</span>
                    <input type="text"  name="name" onChange={onChangHandler} />
                  </label>
        
                  <label className="">
                    <span className="label_name">都道府県</span>
                    <select name="prefecture" onChange={onChangHandler} >
                      <option value="" selected="">都道府県</option>
                      {
                        config.get("PREFS").map(_item => {
                          return <option value={_item.value}>{_item.label}</option>
                        })
                      }               
                    </select>
                  </label>
        
                  <label className="">
                    <span className="label_name">電話番号</span>
                    <input type="text" name="tel" onChange={onChangHandler}/>
                  </label>
                </div>
                <div className="input_wrap">
                  <label className="">
                    <span className="label_name">担当者名</span>
                    <input type="text"  name="name_in_charge" onChange={onChangHandler} />
                  </label>
                  
                </div>
            </div>


          </div>
          <div className="input_wrap"></div>
          <div className="button_wrap center">
            
            <button type="button" onClick={clearHandler}>クリア</button>
            <input type="button" value="検索" className="button red" onClick={clickHandler} />
          </div>
        </div>
        
      </form>
    </div>
  </div>
  );
};


/**
 * 

           
 */